// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants App

// Composants DLC
import {I_Model}    from '../services/I_Model';
import ModelCard    from '../renderers/ModelCard';

// Styles
import './ModelCardsList.scss';

type ModelCardsListProps = {
  models: I_Model[];
  onClick?: any;
}

function ModelCardst(props:ModelCardsListProps) {

  // props
  let {models,onClick} = props;

  // state

  // autres variables

  if(!models)
    return null;

  return (
    <div className="modelCardsList">
      { models.map( (model,index) => (
        <ModelCard key={index} 
          model={model}
          onClick={(e) => onClick(model)} />
      ) ) }
    </div>
  );
}

export default ModelCardst;
