// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Composants génériques
import S_App        from '../../services/S_App';
// import Version      from '../../elements/Version';
// import Credits      from '../../elements/Credits';
// import Saves        from '../../components/Saves';
import { Saves } from 'react-mui-pwa-tools';
import { Version } from 'react-mui-pwa-tools';
import { Credits } from 'react-mui-pwa-tools';


// Composants DLC
import S_Items      from '../services/S_Items';
import S_Models     from '../services/S_Models';
import Model        from '../components/Model';

// Styles
// import './PanelConfig.scss';

import pkg from '../../../package.json';

type PanelConfigProps = {

}

function PanelConfig(props:PanelConfigProps) {

  // props
  // let {} = props;

  // state

  // chargement des donnees

  // autres variables
  const isDEV = !!process.env && process.env.NODE_ENV === 'development';

  return (
    <Container fixed className="panel-contents">

      <div className="mt-vague">
        <h2 className="primary">Types (non paramétrables)</h2>
        <div>
          { S_Items.TYPES.map( (type,index) => (
          <div key={index}>
            <i className={'icon-'+type.svg}></i> { type.name }
            { type.subtypes.map( (subtype,index2) => (
              <span key={index2}><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ subtype.name }</span>
            ) ) }
          </div>
          ) ) }
        </div>
      </div>

    
      <Saves 
        storageKeyPrefix={S_App.STORAGE_KEY_PREFIX}
        storageKeys={[S_Items.STORAGE_KEY_ITEMS,S_Models.STORAGE_KEY_MODELS]}
        shareTitle="Dashboard DLC"
        className="mt-vague" />

      <Version
        development={isDEV}
        version={pkg.version}
        lastUpdate={pkg.lastUpdate} />

      <Credits>
        Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
      </Credits>

    </Container>
  );
}

export default PanelConfig;
