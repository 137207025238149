import S_App      from   '../../services/S_App';
import S_Date     from   '../../services/S_Date';
import {I_Model}  from   '../services/I_Model';
import {I_Item}   from   '../services/I_Item';
import S_Items    from   '../services/S_Items';
import { CONGELATION_TYPES }    from   '../services/C_CongelationTypes';

let S_Models = {
  STORAGE_KEY_MODELS : 'models',

  STATE_FRAIS: 'Frais',
  STATE_SURGELE: 'Surgelé',
  STATE_CONSERVE: 'Conserve',

  PLACE_CELLIER: 'Cellier',
  PLACE_PLACARD: 'Placard',
  PLACE_COMPTOIR: 'Comptoir',
  PLACE_FRIGO: 'Frigo',
  PLACE_FRIZER: 'Frizer',
  PLACE_CONGEL: 'Congel',
  ALL_PLACES: ['Cellier', 'Placard', 'Comptoir', 'Frigo', 'Frizer', 'Congel'],

  CONGELATION_TYPES : CONGELATION_TYPES,

  models : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- fetch()...');
    var models = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Models.STORAGE_KEY_MODELS];
    if(models){
      S_Models.models = JSON.parse(models);
    }else{
      S_Models.models = [];
    }
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- fetch() models',S_Models.models);
    // corrections de contenus
    let someChangesApplied = false;
    for(let item of S_Models.models){
      //
    }
    if(someChangesApplied){
      if(S_App.isLogDebug) console.log('-- S_Models.tsx -- fetch() models after corrections',S_Models.models);
      S_Models.store(() => {});
    }
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Models.STORAGE_KEY_MODELS] = JSON.stringify(S_Models.models);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- store() models',S_Models.models); 
    if(cb)
      cb();
  },

  get: (id:number) => {
    return S_App.getByID(S_Models.models,id);
  },
  getAllByBarcode: (barcode:number) => {
    return S_App.getByFilters(S_Models.models,[{attribute:'barcode',value:barcode}]);
  },

  add(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- add()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationtype: 'DLC',
      conservationtype: S_Items.PLACE_FRIGO,
      conservationtype2: S_Items.PLACE_FRIGO,

      name: '',
      type: undefined,
      subtype: undefined,
      barcode: undefined,

      initialstate: 'Frais',
      congelationtype: undefined
    };
    if(!S_Models.models)
      S_Models.models = [];
    S_Models.models.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- add() added',newOne);
    S_Models.store(() => {
      if(cb) cb(newOne);
    });
  },
  add2(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- add2()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationtype: expirationtype,
      conservationtype: S_Models.getConservationTypeFromInitialState(initialstate),
      conservationtype2: S_Models.getConservationTypeFromInitialState(initialstate),

      name: name,
      type: type,
      subtype: subtype,
      barcode: barcode,

      initialstate: initialstate,
      place: place,
      congelationtype: congelationtype
    };
    if(!S_Models.models)
      S_Models.models = [];
    S_Models.models.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- add2() added',newOne);
    S_Models.store(() => {
      if(cb) cb(newOne);
    });
  },
  copyItemToModel(item:I_Item,cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- copyItemToModel()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationtype: item.expirationtype,
      conservationtype: item.conservationtype,
      conservationtype2: item.conservationtype2,

      name: item.name,
      type: item.type,
      subtype: item.subtype,
      barcode: item.barcode,

      initialstate: item.initialstate,
      congelationtype: item.congelationtype
    };
    if(!S_Models.models)
      S_Models.models = [];
    S_Models.models.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- copyItemToModel() added',newOne);
    S_Models.store(() => {
      if(cb) cb(newOne);
    });
  },
  copyItemValuesToModel(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- copyItemToModel()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationtype: expirationtype,
      conservationtype: S_Models.getConservationTypeFromInitialState(initialstate),
      conservationtype2: S_Models.getConservationTypeFromInitialState(initialstate),

      name: name,
      type: type,
      subtype: subtype,
      barcode: barcode,

      initialstate: initialstate,
      place: place,
      congelationtype: congelationtype
    };
    if(!S_Models.models)
      S_Models.models = [];
    S_Models.models.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- copyItemValuesToModel() added',newOne);
    S_Models.store(() => {
      if(cb) cb(newOne);
    });
  },
  updateField(value,field,model:I_Model,cb){
    if(!S_Models.models || S_Models.models.length===0 || !model){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Models.models.length; i++){
      if(S_Models.models[i].id !== model.id)
        continue;
      foundData = S_Models.models[i];
      if('type'===field || 'subtype'===field || 'barcode'===field || 'congelationtype'===field){
        foundData[field] = value!==undefined ? parseInt(value) : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Models.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Models.tsx -- update() updated',foundData);
    S_Models.store(() => {
      if(cb) cb(foundData);
    });
  },

  update(value,field,model:I_Model,cb){
    if(!S_Models.models || S_Models.models.length===0 || !model){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Models.models.length; i++){
      if(S_Models.models[i].id !== model.id)
        continue;
      foundData = S_Models.models[i];
      if('type'===field || 'subtype'===field || 'barcode'===field || 'congelationtype'===field){
        foundData[field] = value!==undefined ? parseInt(value) : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Models.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Models.tsx -- update() updated',foundData);
    S_Models.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(model:I_Model,cb){
    if(!S_Models.models || S_Models.models.length===0 || !model){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- remove() delete',model,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_Models.models,model.id);
    if(foundIndex !== -1){
      S_Models.models.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Models.tsx -- remove() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Models.tsx -- remove() not found'); 
    }
    S_Models.store(() => {
      if(cb) cb();
    });
  },
  clone(model: I_Model,cb){
    if(!model){
      if(cb) cb();
      return;
    }
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationtype: model.expirationtype,
      conservationtype: model.conservationtype,
      conservationtype2: model.conservationtype,

      name: model.name,
      type: model.type,
      subtype: model.subtype,
      barcode: model.barcode,
      congelationtype: model.congelationtype
    };
    if(!S_Models.models)
      S_Models.models = [];
    S_Models.models.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Models.tsx -- clone() added',newOne);
    S_Models.store(() => {
      if(cb) cb(newOne);
    });
  },


  _getModelsOfType: (models:I_Model[],typeID:number) => {
    if(!models || models.length===0)
      return [];

    let results = [];
    for(var i = 0; i < models.length; i++){
      let item = models[i];
      if(typeID!==undefined && typeID!==null){
        if(!item.type || item.type !== typeID)
          continue;
        results.push(item);
      }else{
        if(item.type!==undefined && item.type!==null)
          continue;
        results.push(item);
      }
    }

    return results;
  },

  _getModelsOfSubType: (models:I_Model[],typeID:number,subtypeID:number) => {
    if(!models || models.length===0)
      return [];

    let results = [];
    if(typeID!==undefined && typeID!==null && subtypeID!==undefined && subtypeID!==null){
      for(var i = 0; i < models.length; i++){
        let item = models[i];
        if(item.type !== typeID)
          continue;
        if(item.subtype !== subtypeID)
          continue;
        results.push(item);
      }
    }else{
      if(typeID!==undefined && typeID!==null && (subtypeID===undefined || subtypeID===null)){
        for(var i = 0; i < models.length; i++){
          let item = models[i];
          if(item.type !== typeID)
            continue;
          if(item.subtype !== undefined && item.subtype !== null)
            continue;
          results.push(item);
        }
      }
    }

    return results;
  },

  _getModelsMatchingName: (name:string) => {
    if(!S_Models.models || S_Models.models.length===0)
      return [];

    let results = [];
    for(var i = 0; i < S_Models.models.length; i++){
      let item = S_Models.models[i];
      if(name!==undefined && name!==null && name.trim().toLowerCase()!==''){
        if(!item.name || item.name.trim().toLowerCase().indexOf(name.trim().toLowerCase())===-1)  // l'extrait n'est pas trouve
          continue;
        results.push(item);
      }else{
        if(item.name!==undefined && item.name!==null && item.name.trim().toLowerCase()!=='')
          continue;
        results.push(item);
      }
    }

    return results;
  },

  _getModelsMatchingBarcode: (barcode:number) => {
    if(!S_Models.models || S_Models.models.length===0)
      return [];
    if(barcode===undefined || barcode===null)
      return [];

    let results = [];
    for(var i = 0; i < S_Models.models.length; i++){
      let item = S_Models.models[i];
      if(item.barcode===undefined || item.barcode===null || item.barcode !== barcode)
        continue;
      results.push(item);
    }

    return results;
  },

  getModelAdv: (model:I_Model) => {
    if(!model)
      return;

    let creationDateFormatted = S_Date.formatDateInString(model.creationdate);

    let creationDelayInDays = S_Date.formatDelayForNow(model.creationdate,true);

    let creationDelayFormatted = S_Date.formatDelayAgo(creationDelayInDays);

    let typeObject = S_App.getByID(S_Items.TYPES,model.type);
    let subtypeObject = typeObject!==undefined ? S_App.getByID(typeObject.subtypes,model.subtype) : undefined;

    let congelationmaxtimeInMonths = undefined;
    if(typeObject!==undefined && model.conservationtype===S_Items.PLACE_FRIGO){
      congelationmaxtimeInMonths = typeObject.congelationmaxtime;
      if(model.type===1)
        congelationmaxtimeInMonths = subtypeObject!==undefined ? subtypeObject.congelationmaxtime : typeObject.congelationmaxtime;
    }

    let congelationmaxtimeInDays = undefined;
    if(typeObject!==undefined && model.conservationtype===S_Items.PLACE_FRIGO){
      congelationmaxtimeInDays = typeObject.congelationmaxtime * 30;
      if(model.type===1)
        congelationmaxtimeInDays = subtypeObject!==undefined ? subtypeObject.congelationmaxtime * 30 : typeObject.congelationmaxtime * 30;
    }

    // depuis combien d'heures l'model a ete cree
    let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(model.creationdate,(new Date()).getTime());

    let modelAdv = {
      creationDateFormatted: creationDateFormatted,
      creationDelayInDays: creationDelayInDays,
      creationDelayFormatted: creationDelayFormatted,

      type: typeObject,
      subtype: subtypeObject,
      congelationmaxtimeInMonths: congelationmaxtimeInMonths,
      congelationmaxtimeInDays: congelationmaxtimeInDays,
      gapBetweenNowAndCreationInHours: gapBetweenNowAndCreationInHours
    };

    return modelAdv;
  },

  copyToItem: (model:I_Model, item:I_Item, cb) => {
    let currentDate = (new Date()).getTime();

    S_Items.update(currentDate,'lastupdate',item,(updatedItem) => {
      S_Items.update(model.expirationtype,'expirationtype',updatedItem,(updatedItem) => {
        S_Items.update(model.conservationtype,'conservationtype',updatedItem,(updatedItem) => {
          S_Items.update(model.conservationtype2,'conservationtype2',updatedItem,(updatedItem) => {
            S_Items.update(model.name,'name',updatedItem,(updatedItem) => {
              S_Items.update(model.type,'type',updatedItem,(updatedItem) => {
                S_Items.update(model.subtype,'subtype',updatedItem,(updatedItem) => {
                  S_Items.update(model.barcode,'barcode',updatedItem,(updatedItem) => {
                    S_Items.update(model.initialstate,'initialstate',updatedItem,(updatedItem) => {
                      S_Items.update(model.place,'place',updatedItem,(updatedItem) => {
                        S_Items.update(model.congelationtype,'congelationtype',updatedItem,(updatedItem) => {
                          if(cb) cb(updatedItem);
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  },


  getConservationTypeFromInitialState(initialstate:String){
    if(initialstate===S_Models.STATE_FRAIS)
      return S_Items.PLACE_FRIGO;
    if(initialstate===S_Models.STATE_SURGELE)
      return S_Items.PLACE_CONGEL;
    return S_Items.PLACE_ETAGERE;
  },
  getExpirationTypeFromInitialState(initialstate:String){
    if(initialstate===S_Models.STATE_FRAIS)
      return 'DLC';
    if(initialstate===S_Models.STATE_SURGELE)
      return 'DLUO';
    return 'DLUO';
  },
  getPlaceFromInitialState(initialstate:String){
    if(initialstate===S_Models.STATE_FRAIS)
      return S_Models.PLACE_FRIGO;
    if(initialstate===S_Models.STATE_SURGELE)
      return S_Models.PLACE_CONGEL;
    return S_Models.PLACE_COMPTOIR;
  }
};
export default S_Models;