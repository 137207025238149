// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques

// Composants propres
import S_Items      from '../services/S_Items';
import S_Models     from '../services/S_Models';
import {I_Model}    from '../services/I_Model';
// import Model        from '../components/Model';
import ModelCardsList    from '../renderers/ModelCardsList';

// Styles
// import './Accordions.scss';

type ModelsAccordionsProps = {
  models: I_Model[];
  // openModalModelDetails: any;
  openModalModelEdition: any;
  // openModalModelDeletion: any;
}

function ModelsAccordions(props:ModelsAccordionsProps) {

  // props
  let {models,openModalModelEdition} = props;

  // state



  // autres variables
  let modelsOfTypeUndefined = S_Models._getModelsOfType(models,undefined);

  return (
    <div>
      { S_Items.TYPES.map( (type,index) => {
          let subtypes = type.subtypes;
          let modelsOfType = S_Models._getModelsOfType(models,type.id);
          if(!modelsOfType || modelsOfType.length===0)
            return null;
          let modelsOfSubTypeUndefined = S_Models._getModelsOfSubType(models,type.id,undefined);
          return (
            <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="capitalize">
                <strong>{ type.name } ({modelsOfType.length})</strong>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w100">
                  {  subtypes.map( (subtype,index2) => {
                    let modelsOfSubType = S_Models._getModelsOfSubType(models,type.id,subtype.id);
                    if(!modelsOfSubType || modelsOfSubType.length===0)
                      return null;
                    return (
                      <div key={index2} className="clear">
                        { subtype.name }
                        <ModelCardsList 
                          models={modelsOfSubType}
                          onClick={openModalModelEdition}/>
                      </div>
                    );
                  }  )  }
                  {modelsOfSubTypeUndefined.length>0 ? (
                  <div className="clear">
                    Autres
                    <ModelCardsList 
                      models={modelsOfSubTypeUndefined}
                      onClick={openModalModelEdition}/>
                  </div>
                  ):null}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } ) }
        {modelsOfTypeUndefined.length>0 ? (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="capitalize">
            <strong>Sans catégorie</strong>
          </AccordionSummary>
          <AccordionDetails>
            <div className="w100">
              <ModelCardsList 
                models={modelsOfTypeUndefined}
                onClick={openModalModelEdition}/>
            </div>
          </AccordionDetails>
        </Accordion>
        ) : null}
    </div>
  );
}

export default ModelsAccordions;
