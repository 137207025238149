import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ToggleButton      from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import S_Items       from '../services/S_Items';
import S_Models      from '../services/S_Models';
import {I_Model}     from '../services/I_Model';

// Styles
// import './DialogAddModel.scss';

type DialogAddModelProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
}

function DialogAddModel(props:DialogAddModelProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  // state
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [subtype, setSubtype] = useState(undefined);
  const [expirationtype, setExpirationtype] = useState('DLC');
  const [initialstate, setInitialstate] = useState('Frais');
  const [place, setPlace] = useState(S_Models.PLACE_FRIGO);
  const [congelationtype, setCongelationtype] = useState(undefined);

  const [subtypes, setSubtypes] = useState([]);

  function changeName(value){
    setName(value);
  }
  function changeBarcode(value){
    if(value===undefined || value===null || value.trim()==='')
      value = undefined;
    setBarcode(value);
  }
  function changeType(value){
    setType(value);
    setSubtypes(S_App.getByID(S_Items.TYPES,value).subtypes);
    setSubtype(undefined);
  }
  function changeSubtype(value){
    setSubtype(value);
  }
  function changeExpirationtype(value){
    setExpirationtype(value);
  }
  function changeInitialstate(value){
    setInitialstate(value);
  }
  function changePlace(value){
    setPlace(value);
  }
  function changeCongelationtype(value){
    setCongelationtype(value);
  }

  // console.log('type',type);
  // console.log('subtypes',subtypes);

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Ajouter un modèle</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField id="barcode" label={'Code barre'} type="text" fullWidth size="small" InputLabelProps={{ shrink: true }}
            value={barcode}
            onChange={(e) => changeBarcode(e.target.value)} />
        </div>

        <div className="mv-1">
          <TextField id="name" label={'Nom'} type="text" fullWidth size="small" autoFocus={true} InputLabelProps={{ shrink: true }}
            value={name}
            onChange={(e) => changeName(e.target.value)} />
        </div>

        <div className="mv-1">
          <FormControl size="small" fullWidth>
            <InputLabel id="label-type">Type</InputLabel>
            <Select labelId="label-type" labelWidth={80} name="type" 
              value={type} 
              onChange={(e) => changeType(e.target.value)}>
              { S_Items.TYPES.map( (one_type,index) => (
                <MenuItem value={one_type.id} key={index}>
                  <ListItemIcon className="list-item-icon">
                    <i className={'icon-'+one_type.svg}></i> 
                  </ListItemIcon>
                  { one_type.name }
                </MenuItem>
              ) ) }
            </Select>
          </FormControl>
        </div>

        {type!==undefined && subtypes.length>0 ? (
          <div className="mv-1">
            <FormControl size="small" fullWidth>
              <InputLabel id="label-subtype">Sous-type</InputLabel>
              <Select labelId="label-subtype" labelWidth={80} name="subtype" 
                value={subtype} 
                onChange={(e) => changeSubtype(e.target.value)}>
                { subtypes.map( (one_subtype,index) => (
                  <MenuItem value={one_subtype.id} key={index}>{ one_subtype.name }</MenuItem>
                ) ) }
              </Select>
            </FormControl>
          </div>
        ) : null}

        <div className="text-left mv-1">
          <div>Expiration</div>
          <ToggleButtonGroup value={expirationtype} exclusive onChange={(event, newAlignment) => changeExpirationtype(newAlignment)} aria-label="Expiration" size="small">
            <ToggleButton value="DLC" aria-label="DLC">
              DLC
            </ToggleButton>
            <ToggleButton value="DLUO" aria-label="DLUO">
              DLUO
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="text-left mv-1">
          <div>Etat initial</div>
          <ToggleButtonGroup value={initialstate} exclusive onChange={(event, newAlignment) => changeInitialstate(newAlignment)} aria-label="Etat initial" size="small">
            <ToggleButton value="Frais" aria-label="Frais">
              Frais
            </ToggleButton>
            <ToggleButton value="Surgelé" aria-label="Surgelé">
              Surgelé
            </ToggleButton>
            <ToggleButton value="Conserve" aria-label="Conserve">
              Conserve
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="text-left mv-1">
          <div>Lieu de conservation</div>
          <div className="overflow-x">
            <ToggleButtonGroup value={place} exclusive onChange={(event, newAlignment) => changePlace(newAlignment)} aria-label="Lieu de conservation" size="small">
              <ToggleButton value={S_Models.PLACE_CELLIER} aria-label={S_Models.PLACE_CELLIER}>
                {S_Models.PLACE_CELLIER}
              </ToggleButton>
              <ToggleButton value={S_Models.PLACE_PLACARD} aria-label={S_Models.PLACE_PLACARD}>
                {S_Models.PLACE_PLACARD}
              </ToggleButton>
              <ToggleButton value={S_Models.PLACE_COMPTOIR} aria-label={S_Models.PLACE_COMPTOIR}>
                {S_Models.PLACE_COMPTOIR}
              </ToggleButton>
              <ToggleButton value={S_Models.PLACE_FRIGO} aria-label={S_Models.PLACE_FRIGO}>
                {S_Models.PLACE_FRIGO}
              </ToggleButton>
              <ToggleButton value={S_Models.PLACE_FRIZER} aria-label={S_Models.PLACE_FRIZER}>
                {S_Models.PLACE_FRIZER}
              </ToggleButton>
              <ToggleButton value={S_Models.PLACE_CONGEL} aria-label={S_Models.PLACE_CONGEL}>
                {S_Models.PLACE_CONGEL}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        <div className="text-left mv-1">
          <FormControl size="small" fullWidth>
            <InputLabel id="label-congelationtype">Congélation</InputLabel>
            <Select labelId="label-congelationtype" labelWidth={80} name="congelationtype" 
              value={congelationtype} 
              onChange={(e) => changeCongelationtype(e.target.value)}>
              { S_Models.CONGELATION_TYPES.map( (type,index) => (
                <MenuItem value={type.id} key={index}>{ type.name }</MenuItem>
              ) ) }
            </Select>
          </FormControl>
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddModel;
