import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import CodeIcon from '@mui/icons-material/Code';

// Composants DLC

// Styles
import './Barcode.scss';

type BarcodeProps = {
  barcode: number;
}

function Barcode(props:BarcodeProps) {

  // props
  let {barcode} = props;



  if(barcode==undefined || barcode==null)
    return <div className="barcode unknown"><CodeIcon/> Non défini</div>;

  return <div className="barcode"><CodeIcon/> {barcode}</div>;
}

export default Barcode;
