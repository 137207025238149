import React, { useState, useEffect } from 'react';

// Composants Material UI
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import StarIcon from '@material-ui/icons/Star';
import Snackbar from '@mui/material/Snackbar';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Composants generiques
// import Panels       from './components/Panels';
import S_App        from './services/S_App';
import S_Date       from './services/S_Date';
import { Panels } from 'react-mui-pwa-tools';

// Composants DLC
import S_Items         from './metier/services/S_Items';
import {I_Item}        from './metier/services/I_Item';
import S_Models        from './metier/services/S_Models';
import {I_Model}       from './metier/services/I_Model';
import PanelStocks     from './metier/panels/PanelStocks';
import PanelConfig     from './metier/panels/PanelConfig';
import PanelSemainier  from './metier/panels/PanelSemainier';
import PanelModels     from './metier/panels/PanelModels';
import ModalItemDetails  from './metier/modals/ModalItemDetails';
import ModalItemEdition  from './metier/modals/ModalItemEdition';
import ModalItemDeletion from './metier/modals/ModalItemDeletion';

import DialogScan           from './metier/dialogs/DialogScan';
import DialogSearchByName   from './metier/dialogs/DialogSearchByName';

import DialogAddModel     from './metier/dialogs/DialogAddModel';
import DialogEditModel    from './metier/dialogs/DialogEditModel';

import DialogEditItemFromModel    from './metier/dialogs/DialogEditItemFromModel';
import DialogEditItemFromName     from './metier/dialogs/DialogEditItemFromName';
import DialogEditItemFromBarcode  from './metier/dialogs/DialogEditItemFromBarcode';
import DialogEditItem             from './metier/dialogs/DialogEditItem';

// Styles
import './App.scss';

function App() {
  const [swipePanelAllowed, setSwipePanelAllowed] = useState(true);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(undefined);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(undefined);
  const [modalItemDetailsOpened, setModalItemDetailsOpened] = useState(false);
  const [modalItemEditionOpened, setModalItemEditionOpened] = useState(false);
  const [modalItemDeletionOpened, setModalItemDeletionOpened] = useState(false);

  const [isDialogScanOpened, setIsDialogScanOpened] = useState(false);
  const [dialogScanAdd, setDialogScanAdd] = useState(false);
  const [isDialogSearchByNameOpened, setIsDialogSearchByNameOpened] = useState(false);
  const [dialogSearchAdd, setDialogSearchAdd] = useState(false);

  const [stocksView, setStocksView] = useState(0);
  const [stocksGroupBy, setStocksGroupBy] = useState(0);

  const [modelToEdit, setModelToEdit] = useState(undefined);
  const [isDialogAddModelOpened, setIsDialogAddModelOpened] = useState(false);
  const [isDialogEditModelOpened, setIsDialogEditModelOpened] = useState(false);

  const [itemToEdit, setItemToEdit] = useState(undefined);
  const [isDialogEditItemFromModelOpened, setIsDialogEditItemFromModelOpened] = useState(false);
  const [isDialogEditItemFromNameOpened, setIsDialogEditItemFromNameOpened] = useState(false);
  const [isDialogEditItemFromBarcodeOpened, setIsDialogEditItemFromBarcodeOpened] = useState(false);
  const [isDialogEditItemOpened, setIsDialogEditItemOpened] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(undefined);

  // breakpoints
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // chargement des donnees
  useEffect(() => {
    S_Items.fetch(() => {
      setItems(S_Items.items);
    });
    S_Models.fetch(() => {
      setModels(S_Models.models);
    });
  }, []);

  if(isSmallScreen && stocksView===2)
    setStocksView(0);

  // fonctions
  function closeModalItemDetails(){
    setModalItemDetailsOpened(false);
  }
  function openModalItemDetails(item:I_Item){
    setItem(item);
    setModalItemDetailsOpened(true);
  }
  function closeModalItemEdition(){
    setModalItemEditionOpened(false);
  }
  function openModalItemEdition(item:I_Item){
    setItem(item);
    setModalItemEditionOpened(true);
  }
  function closeModalItemDeletion(){
    setModalItemDeletionOpened(false);
  }
  function openModalItemDeletion(item:I_Item){
    setItem(item);
    setModalItemDeletionOpened(true);
  }

  

  // changement d'une valeur de champ d'un item
  function changeFieldItemFromEdition(value,fieldName,item){
    // console.log('changeFieldItemFromEdition',value,fieldName,item);
    S_Items.updateField(value,fieldName,item,(foundData) => {
      if(!foundData)
        return;
      setItems([...S_Items.items]);
      setItem(foundData);
    });
  }

  // ajout d'un item et ouverture de la popin edition
  function addItemAndEdit(){
    S_Items.add((createdData) => {
      if(!createdData)
        return;
      setItems([...S_Items.items]);
      setItem(createdData);
      openModalItemEdition(createdData);
    });
  }

  // confirmation de suppression de l'item en cours
  function confirmDeleteItem(){
    closeModalItemDetails();
    closeModalItemEdition();
    closeModalItemDeletion();
    S_Items.remove(item,() => {
      setItems([...S_Items.items]);
      setItem(undefined);
      setIsDialogEditItemOpened(false);
      setIsDialogEditItemFromBarcodeOpened(false);
      setIsDialogEditItemFromModelOpened(false);
      setIsDialogEditItemFromNameOpened(false);
      setItemToEdit(undefined);
    });
  }

  

  

  

  

  // copie les data du model dans l'item courant
  function copyModelToItem(model:I_Model){
    S_Models.copyToItem(model,item,(updatedData) => {
      if(!updatedData)
        return;
      setItems([...S_Items.items]);
      setItem(updatedData);
    });
  }





  // ------------------------------------------------------------------
  //          MODEL
  // ------------------------------------------------------------------


  function addModel(){
    setIsDialogAddModelOpened(true);
  }
  function addModelValidate(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype){
    S_Models.add2(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,() => {
      setModels([...S_Models.models]);
      setIsDialogAddModelOpened(false);
    });
  }
  function editModel(model:I_Model){
    setModelToEdit(model);
    setIsDialogEditModelOpened(true);
  }
  function editModelValidate(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype){
    S_Models.update(name,'name',modelToEdit,() => {
      S_Models.update(barcode,'barcode',modelToEdit,() => {
        S_Models.update(type,'type',modelToEdit,() => {
          S_Models.update(subtype,'subtype',modelToEdit,() => {
            S_Models.update(expirationtype,'expirationtype',modelToEdit,() => {
              S_Models.update(initialstate,'initialstate',modelToEdit,() => {
                S_Models.update(place,'place',modelToEdit,() => {
                  S_Models.update(congelationtype,'congelationtype',modelToEdit,() => {
                    setModels([...S_Models.models]);
                    setIsDialogEditModelOpened(false);
                    setModelToEdit(undefined);
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  function editModelDelete(){
    S_Models.remove(modelToEdit,() => {
      setModels([...S_Models.models]);
      setIsDialogEditModelOpened(false);
      setModelToEdit(undefined);
    });
  }
  function closeEditModel(){
    setIsDialogEditModelOpened(false);
    setModelToEdit(undefined);
  }


  // ------------------------------------------------------------------
  //          COPY FROM MODEL
  // ------------------------------------------------------------------

  function addScan(){
    setIsDialogScanOpened(true);
  }
  function addSearchByName(){
    setIsDialogSearchByNameOpened(true);
  }

  function foundByBarcode(model:I_Model){
    // setIsDialogScanOpened(false);

    S_Items.add((createdData) => {
      if(!createdData)
        return;
      S_Models.copyToItem(model,createdData,(updatedData) => {
        if(!updatedData)
          return;
        setItems([...S_Items.items]);
        setItemToEdit(updatedData);
        setIsDialogEditItemFromModelOpened(updatedData);
      });
    });
  }
  function foundByName(model:I_Model){
    setIsDialogSearchByNameOpened(false);

    S_Items.add((createdData) => {
      if(!createdData)
        return;
      S_Models.copyToItem(model,createdData,(updatedData) => {
        if(!updatedData)
          return;
        setItems([...S_Items.items]);
        setItemToEdit(updatedData);
        setIsDialogEditItemFromModelOpened(updatedData);
      });
    });
  }

  function createItemFromName(name:string){
    setIsDialogSearchByNameOpened(false);

    S_Items.add((createdData) => {
      if(!createdData)
        return;
      S_Items.update(name,'name',createdData,(updatedData) => {
        if(!updatedData)
          return;
        setItems([...S_Items.items]);
        setItemToEdit(updatedData);
        setIsDialogEditItemFromNameOpened(updatedData);
      });
    });
  }

  function createItemFromBarcode(barcode:number){
    setIsDialogScanOpened(false);

    S_Items.add((createdData) => {
      if(!createdData)
        return;
      S_Items.update(barcode,'barcode',createdData,(updatedData) => {
        if(!updatedData)
          return;
        setItems([...S_Items.items]);
        setItemToEdit(updatedData);
        setIsDialogEditItemFromBarcodeOpened(updatedData);
      });
    });
  }


  // ------------------------------------------------------------------
  //          ITEM
  // ------------------------------------------------------------------

  function editItemFromModel(item:I_Item){
    setItemToEdit(item);
    setIsDialogEditItemFromModelOpened(true);
  }
  function editItemFromModelValidate(name,barcode,type,subtype,expirationtype,initialstate,expirationdate,place,congelationtype){
    S_Items.update(name,'name',itemToEdit,() => {
      S_Items.update(barcode,'barcode',itemToEdit,() => {
        S_Items.update(type,'type',itemToEdit,() => {
          S_Items.update(subtype,'subtype',itemToEdit,() => {
            S_Items.update(expirationtype,'expirationtype',itemToEdit,() => {
              S_Items.update(initialstate,'initialstate',itemToEdit,() => {
                S_Items.update(expirationdate,'expirationdate',itemToEdit,() => {
                  S_Items.update(place,'place',itemToEdit,() => {
                    S_Items.update(congelationtype,'congelationtype',itemToEdit,() => {
                      setItems([...S_Items.items]);
                      setIsDialogEditItemFromModelOpened(false);
                      setItemToEdit(undefined);
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  function editItemFromModelDelete(){
    S_Items.remove(itemToEdit,() => {
      setItems([...S_Items.items]);
      setIsDialogEditItemFromModelOpened(false);
      setItemToEdit(undefined);
    });
  }
  function closeEditItemFromModel(){
    setIsDialogEditItemFromModelOpened(false);
    setItemToEdit(undefined);
  }

  function editItemFromName(item:I_Item){
    setItemToEdit(item);
    setIsDialogEditItemFromNameOpened(true);
  }
  function editItemFromNameValidate(name,barcode,type,subtype,expirationtype,initialstate,expirationdate,place,congelationtype){
    S_Items.update(name,'name',itemToEdit,() => {
      S_Items.update(barcode,'barcode',itemToEdit,() => {
        S_Items.update(type,'type',itemToEdit,() => {
          S_Items.update(subtype,'subtype',itemToEdit,() => {
            S_Items.update(expirationtype,'expirationtype',itemToEdit,() => {
              S_Items.update(initialstate,'initialstate',itemToEdit,() => {
                S_Items.update(expirationdate,'expirationdate',itemToEdit,() => {
                  S_Items.update(place,'place',itemToEdit,() => {
                    S_Items.update(congelationtype,'congelationtype',itemToEdit,() => {
                      setItems([...S_Items.items]);
                      setIsDialogEditItemFromNameOpened(false);
                      setItemToEdit(undefined);
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  function editItemFromNameDelete(){
    S_Items.remove(itemToEdit,() => {
      setItems([...S_Items.items]);
      setIsDialogEditItemFromNameOpened(false);
      setItemToEdit(undefined);
    });
  }
  function editItemFromNameFavori(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype){
    S_Models.copyItemValuesToModel(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,(createdData) => {
      if(!createdData)
        return;
      setModels([...S_Models.models]);
      setSnackbarMessage('Favori créé');
      setOpenSnackbar(true);
    });
  }
  function closeEditItemFromName(){
    setIsDialogEditItemFromNameOpened(false);
    setItemToEdit(undefined);
  }

  function editItemFromBarcode(item:I_Item){
    setItemToEdit(item);
    setIsDialogEditItemFromBarcodeOpened(true);
  }
  function editItemFromBarcodeValidate(name,barcode,type,subtype,expirationtype,initialstate,expirationdate,place,congelationtype){
    S_Items.update(name,'name',itemToEdit,() => {
      S_Items.update(barcode,'barcode',itemToEdit,() => {
        S_Items.update(type,'type',itemToEdit,() => {
          S_Items.update(subtype,'subtype',itemToEdit,() => {
            S_Items.update(expirationtype,'expirationtype',itemToEdit,() => {
              S_Items.update(initialstate,'initialstate',itemToEdit,() => {
                S_Items.update(expirationdate,'expirationdate',itemToEdit,() => {
                  S_Items.update(place,'place',itemToEdit,() => {
                    S_Items.update(congelationtype,'congelationtype',itemToEdit,() => {
                      setItems([...S_Items.items]);
                      setIsDialogEditItemFromBarcodeOpened(false);
                      setItemToEdit(undefined);
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  function editItemFromBarcodeDelete(){
    S_Items.remove(itemToEdit,() => {
      setItems([...S_Items.items]);
      setIsDialogEditItemFromBarcodeOpened(false);
      setItemToEdit(undefined);
    });
  }
  function editItemFromBarcodeFavori(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype){
    S_Models.copyItemValuesToModel(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,(createdData) => {
      if(!createdData)
        return;
      setModels([...S_Models.models]);
      setSnackbarMessage('Favori créé');
      setOpenSnackbar(true);
    });
  }
  function closeEditItemFromBarcode(){
    setIsDialogEditItemFromBarcodeOpened(false);
    setItemToEdit(undefined);
  }

  function editItem(item:I_Item){
    setItemToEdit(item);
    setIsDialogEditItemOpened(true);
  }
  function closeEditItem(){
    setIsDialogEditItemOpened(false);
    setItemToEdit(undefined);
  }
  function editItemDelete(){
    setModalItemDeletionOpened(true);
    // S_Items.remove(itemToEdit,() => {
    //   setItems([...S_Items.items]);
    //   setIsDialogEditItemOpened(false);
    //   setItemToEdit(undefined);
    // });
  }
  function editItemValidate(name,barcode,type,subtype,expirationtype,initialstate,expirationdate,place,congelationtype,congelationdate,decongelationdate,openingdate,previsionaldate){
    // NB les dates sont au format material, on les transfome dans le S_Items.update
    if(!expirationdate || expirationdate===null || expirationdate==='') expirationdate = undefined;
    if(!congelationdate || congelationdate===null || congelationdate==='') congelationdate = undefined;
    if(!decongelationdate || decongelationdate===null || decongelationdate==='') decongelationdate = undefined;
    if(!openingdate || openingdate===null || openingdate==='') openingdate = undefined;
    if(!previsionaldate || previsionaldate===null || previsionaldate==='') previsionaldate = undefined;
    S_Items.update(name,'name',itemToEdit,() => {
      S_Items.update(barcode,'barcode',itemToEdit,() => {
        S_Items.update(type,'type',itemToEdit,() => {
          S_Items.update(subtype,'subtype',itemToEdit,() => {
            S_Items.update(expirationtype,'expirationtype',itemToEdit,() => {
              S_Items.update(initialstate,'initialstate',itemToEdit,() => {
                S_Items.update(expirationdate,'expirationdate',itemToEdit,() => {
                  S_Items.update(place,'place',itemToEdit,() => {
                    S_Items.update(congelationtype,'congelationtype',itemToEdit,() => {
                      S_Items.update(congelationdate,'congelationdate',itemToEdit,() => {
                        S_Items.update(decongelationdate,'decongelationdate',itemToEdit,() => {
                          S_Items.update(openingdate,'openingdate',itemToEdit,() => {
                            S_Items.update(previsionaldate,'previsionaldate',itemToEdit,() => {
                              setItems([...S_Items.items]);
                              setIsDialogEditItemOpened(false);
                              setItemToEdit(undefined);
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
  function editItemFavori(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype){
    S_Models.copyItemValuesToModel(name,barcode,type,subtype,expirationtype,initialstate,place,congelationtype,(createdData) => {
      if(!createdData)
        return;
      setModels([...S_Models.models]);
      setSnackbarMessage('Favori créé');
      setOpenSnackbar(true);
    });
  }

  // clone l'item
  function cloneItem(item) {
    S_Items.clone(item,(newItem) => {
      setItems([...S_Items.items]);
      setItem(newItem);
    });
  }
  // copie l'item dans les favoris
  function copyItemToModels(item){
    S_Models.copyItemToModel(item,(createdData) => {
      if(!createdData)
        return;
      setModels([...S_Models.models]);
    });
  }
  // ajoute une date d'ouverture
  function setItemOpened(item){
    let newDate = S_Date.formatDateForMaterial((new Date()).getTime());
    S_Items.updateField(newDate,'openingdate',item,() => {
      setItems([...S_Items.items]);
      closeModalItemDetails();
      closeModalItemEdition();
    });
  }
  function setItemToCongel(item:I_Item){
    // ajoute une date de congelation
    let newDate = S_Date.formatDateForMaterial((new Date()).getTime());
    S_Items.updateField(newDate,'congelationdate',item,() => {
      // place = congel
      S_Items.updateField(S_Models.PLACE_CONGEL,'place',item,() => {
        setItems([...S_Items.items]);
        closeModalItemDetails();
        closeModalItemEdition();
      });
    });
  }
  function setItemToDecongel(item){
    // ajoute une date de decongelation
    let newDate = S_Date.formatDateForMaterial((new Date()).getTime());
    S_Items.updateField(newDate,'decongelationdate',item,() => {
      // place = frigo
      S_Items.updateField(S_Models.PLACE_FRIGO,'place',item,() => {
        setItems([...S_Items.items]);
        closeModalItemDetails();
        closeModalItemEdition();
      });
    });
  }

  // ------------------------------------------------------------------

  function closeSnackbar(){
    setSnackbarMessage('');
    setOpenSnackbar(false);
  }

  // ------------------------------------------------------------------
  

  // configuration des panels
  let panelContents = [
    <PanelSemainier 
      isSmallScreen={isSmallScreen}
      openModalItemDetails={openModalItemDetails}
      openModalItemEdition={openModalItemEdition}
      openModalItemDeletion={openModalItemDeletion}/>,
    <PanelStocks 
      items={items}
      stocksView={stocksView}
      setStocksView={setStocksView}
      isSmallScreen={isSmallScreen}
      stocksGroupBy={stocksGroupBy}
      setStocksGroupBy={setStocksGroupBy}
      openModalItemDetails={openModalItemDetails}
      openModalItemEdition={openModalItemEdition}
      openModalItemDeletion={openModalItemDeletion}
      addItemAndEdit={addItemAndEdit}
      addScan={addScan}
      addSearchByName={addSearchByName}
      onClickEdit={editItem}
      onClickClone={cloneItem}
      onClickFavori={copyItemToModels}
      onClickToOpen={setItemOpened}
      onClickToCongel={setItemToCongel}
      onClickToFrigo={setItemToDecongel} />,
    <PanelModels 
      models={models}
      openModalModelEdition={editModel}
      addModel={addModel} />,
    <PanelConfig />
  ];
  let outdatedItems = S_Items._filterItemsOfStatus(S_Items.items,['grey','red','orange','blue']);
  let tabs = [
    {
      label: 'Semainier',
      icon: <DateRangeOutlinedIcon />
    },
    {
      label: 'Stocks',
      icon: <StorageOutlinedIcon />,
      className: outdatedItems.length>0 ? 'outdated outdated-'+outdatedItems.length : ''
    },
    {
      label: 'Favoris',
      icon: <StarIcon />
    },
    {
      label: 'Config',
      icon: <MoreHorizOutlinedIcon /> 
    }
  ];

  return (
    <>
    <Panels 
      enabled={swipePanelAllowed}
      contents={panelContents}
      tabs={tabs}
      defaultPanelIndex={1}
      tabsLabelOnSmallScreen={true}
      size="small"/>

    {modalItemDetailsOpened ? (
    <ModalItemDetails 
      item={item}
      isModalOpened={modalItemDetailsOpened}
      closeModal={closeModalItemDetails}
      onClickDelete={openModalItemDeletion}
      onClickEdit={openModalItemEdition}
      onClickToCongel={setItemToCongel}
      onClickToFrigo={setItemToDecongel}
      onClickClone={cloneItem}
      onClickToOpen={setItemOpened}
      onClickFavori={copyItemToModels}/>
    ) : null}
    {modalItemEditionOpened ? (
    <ModalItemEdition 
      item={item}
      isModalOpened={modalItemEditionOpened}
      closeModal={closeModalItemEdition}
      onFieldChange={changeFieldItemFromEdition}
      onClickDelete={openModalItemDeletion}
      onClickToCongel={setItemToCongel}
      onClickToFrigo={setItemToDecongel}
      onClickClone={cloneItem}
      onClickToOpen={setItemOpened}
      onClickFavori={copyItemToModels}
      copyFromModel={copyModelToItem}/>
    ) : null}
    {modalItemDeletionOpened ? (
    <ModalItemDeletion 
      item={item}
      isModalOpened={modalItemDeletionOpened}
      closeModal={closeModalItemDeletion}
      onConfirmDelete={confirmDeleteItem}/>
    ) : null}

    

    <DialogScan
      isDialogOpened={isDialogScanOpened}
      closeDialog={(e) => setIsDialogScanOpened(false)}
      createItemFromModel={foundByBarcode}
      createItemFromBarcode={createItemFromBarcode}
      editModel={editModel} />
    <DialogSearchByName
      isDialogOpened={isDialogSearchByNameOpened}
      closeDialog={(e) => setIsDialogSearchByNameOpened(false)}
      createItemFromModel={foundByName}
      createItemFromName={createItemFromName}
      editModel={editModel} />

    <DialogAddModel
      isDialogOpened={isDialogAddModelOpened}
      closeDialog={(e) => setIsDialogAddModelOpened(false)}
      onClickValidate={addModelValidate} />
    {modelToEdit ? (
    <DialogEditModel
      isDialogOpened={isDialogEditModelOpened}
      closeDialog={closeEditModel}
      onClickDelete={editModelDelete}
      onClickValidate={editModelValidate}
      model={modelToEdit} />
    ):null}

    {itemToEdit ? (
    <>
    <DialogEditItemFromModel
      isDialogOpened={isDialogEditItemFromModelOpened}
      closeDialog={closeEditItemFromModel}
      onClickDelete={editItemFromModelDelete}
      onClickValidate={editItemFromModelValidate}
      item={itemToEdit} />
    <DialogEditItemFromName
      isDialogOpened={isDialogEditItemFromNameOpened}
      closeDialog={closeEditItemFromName}
      onClickDelete={editItemFromNameDelete}
      onClickValidate={editItemFromNameValidate}
      onClickFavori={editItemFromNameFavori}
      item={itemToEdit} />
    <DialogEditItemFromBarcode
      isDialogOpened={isDialogEditItemFromBarcodeOpened}
      closeDialog={closeEditItemFromBarcode}
      onClickDelete={editItemFromBarcodeDelete}
      onClickValidate={editItemFromBarcodeValidate}
      onClickFavori={editItemFromBarcodeFavori}
      item={itemToEdit} />
    <DialogEditItem
      isDialogOpened={isDialogEditItemOpened}
      closeDialog={closeEditItem}
      onClickDelete={editItemDelete}
      onClickValidate={editItemValidate}
      onClickFavori={editItemFavori}
      item={itemToEdit} />
    </>
    ):null}

    <Snackbar
      open={openSnackbar}
      autoHideDuration={2000}
      onClose={closeSnackbar}
      message={snackbarMessage} />

    </>
  );
}

export default App;