// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Models      from '../services/S_Models';
import S_Items       from '../services/S_Items';
import {I_Item}      from '../services/I_Item';
import Item          from '../components/Item';
import ItemAsCard    from '../components/ItemAsCard';

// Styles
// import './StocksByPlaceAsCards.scss';

type StocksByPlaceAsCardsProps = {
  items: I_Item[];
  stocksFilters: number;    // 0 | 1 | 2

  openItemDetails: any;
  openItemEdition: any;
  openItemDeletion: any;

  editItem: any;
  cloneItem: any;
  addInModels: any;
  openItem: any;
  congelItem: any;
  freshItem: any;
}

function StocksByPlaceAsCards(props:StocksByPlaceAsCardsProps) {

  // props
  let {items,stocksFilters,openItemDetails,openItemEdition,openItemDeletion,editItem,cloneItem,addInModels,openItem,congelItem,freshItem} = props;

  // state


  // autres variables
  let itemsOfNoPlace = S_App.getByFilters(items,[{attribute:'place',value:undefined}]);
  itemsOfNoPlace = S_Items.sortByExpirationDate(itemsOfNoPlace);

  return (
    <div className="panel-contents-content">
      <Grid container className="mb-2">
        { S_Models.ALL_PLACES.map( (place,index) => {
          let itemsOfPlace = S_App.getByFilters(items,[{attribute:'place',value:place}]);
          itemsOfPlace = S_Items.sortByExpirationDate(itemsOfPlace);
          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card className="m-v-1">
                <CardHeader title={<h2>{place}</h2>} />
                <CardContent>
                  { itemsOfPlace.map((item,index2) => {
                    // filtre created
                    let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(item.creationdate,(new Date()).getTime());
                    if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
                      return null;
                    return (
                      <ItemAsCard key={item.id} 
                        item={item} 
                        onClick={(e) => openItemDetails(item)}
                        onClickEdit={editItem}
                        onClickDelete={() => openItemDeletion(item)}
                        onClickClone={cloneItem}
                        onClickFavori={addInModels}
                        onClickToOpen={openItem}
                        onClickToCongel={congelItem}
                        onClickToFrigo={freshItem} />
                    );
                  }) }
                </CardContent>
              </Card>
            </Grid>
          );
        } ) }
        <Grid item xs={12} md={6} lg={4}>
          <Card className="m-v-1">
            <CardHeader
              title={<h2>Sans lieu de stockage</h2>} />
            <CardContent>
              { itemsOfNoPlace.map((item,index2) => {
                return (
                  <ItemAsCard key={item.id} 
                    item={item} 
                    onClick={(e) => openItemDetails(item)}
                    onClickEdit={editItem}
                    onClickDelete={() => openItemDeletion(item)}
                    onClickClone={cloneItem}
                    onClickFavori={addInModels}
                    onClickToOpen={openItem}
                    onClickToCongel={congelItem}
                    onClickToFrigo={freshItem} />
                );
              }) }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default StocksByPlaceAsCards;
