// Composant version 2021
// ----------------------------------

import React, { useState } from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import AddIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

import Fab from '@material-ui/core/Fab';

import RemoveIcon from '@material-ui/icons/Remove';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import CategoryIcon from '@material-ui/icons/Category';
import PlaceIcon from '@material-ui/icons/Place';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

// Composants generiques
import S_App        from '../../services/S_App';
import S_Date       from '../../services/S_Date';

// Composants DLC
import S_Models      from '../services/S_Models';
import S_Items      from '../services/S_Items';
import {I_Item}    from '../services/I_Item';
import Item         from '../components/Item';
import StocksByPlaceAsCards  from '../components/StocksByPlaceAsCards';
import StocksByPlaceAsTable  from '../components/StocksByPlaceAsTable';
import StocksByTypeAsCards   from '../components/StocksByTypeAsCards';
import StocksByTypeAsTable   from '../components/StocksByTypeAsTable';

// Styles
// import './PanelStocks.scss';

type PanelStocksProps = {
  items: I_Item[];

  stocksView: number;
  setStocksView: any;
  isSmallScreen: boolean;
  stocksGroupBy: number;
  setStocksGroupBy: any;
  openModalItemDetails: any;
  openModalItemEdition: any;
  openModalItemDeletion: any;
  addItemAndEdit: any;

  addScan: any;
  addSearchByName: any;
  
  onClickEdit?: any;
  onClickClone?: any;
  onClickFavori?: any;
  onClickToOpen?: any;
  onClickToCongel?: any;
  onClickToFrigo?: any;
}

function PanelStocks(props:PanelStocksProps) {

  // props
  let {items,stocksView,setStocksView,isSmallScreen,stocksGroupBy,setStocksGroupBy,openModalItemDetails,openModalItemEdition,openModalItemDeletion,addItemAndEdit,onClickEdit,onClickClone,onClickFavori,onClickToOpen,onClickToCongel,onClickToFrigo,addScan,addSearchByName} = props;

  // state
  const [stocksFilters, setStocksFilters] = useState(0);

  // autres variables
  console.log('stocksFilters',stocksFilters);

  return (
    <Container fixed className="panel-contents">

      <div className="p-v-1 panel-contents-header">
        <Container>
          <Grid container>
            <Grid item xs={7}>
              <ButtonGroup aria-label="Grouper par">
                <Button variant={stocksGroupBy === 0 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksGroupBy(0)} size="small" title="Catégorie">
                  {!isSmallScreen ? 'par Catégorie' : <CategoryIcon/>}
                </Button>
                <Button variant={stocksGroupBy === 1 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksGroupBy(1)} size="small" title="Lieu de stockage">
                  {!isSmallScreen ? 'par Lieu de stockage' : <PlaceIcon/>}
                </Button>
              </ButtonGroup>
              {!isSmallScreen ? (
              <ButtonGroup aria-label="Affichage">
                <Button variant={stocksView === 0 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksView(0)} size="small" title="Petit"><RemoveIcon /></Button>
                <Button variant={stocksView === 2 ? 'contained' : 'outlined'} onClick={(e) => setStocksView(2)} size="small" title="Tableau"><ViewColumnIcon /></Button>
              </ButtonGroup>
              ) : null}
            </Grid>
            <Grid item xs={5} className="text-right">
              <ButtonGroup aria-label="Filtres">
                <Button variant={stocksFilters === 0 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksFilters(0)} size="small" title="Aucun" className={isSmallScreen ? 'empty' : ''}>
                  {!isSmallScreen ? 'Aucun filtre' : 'Tout'}
                </Button>
                <Button variant={stocksFilters === 1 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksFilters(1)} size="small" title="1h">
                  {!isSmallScreen ? '< 1h' : '<1h'}
                </Button>
                <Button variant={stocksFilters === 4 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setStocksFilters(4)} size="small" title="4h">
                  {!isSmallScreen ? '< 4h' : '<4h'}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Container>
      </div>

      { stocksGroupBy === 0 && stocksView !== 2 ? (
      <StocksByTypeAsCards
        items={items}
        stocksFilters={stocksFilters}
        openItemDetails={openModalItemDetails}
        openItemEdition={openModalItemEdition}
        openItemDeletion={openModalItemDeletion}
        editItem={onClickEdit}
        cloneItem={onClickClone}
        addInModels={onClickFavori}
        openItem={onClickToOpen}
        congelItem={onClickToCongel}
        freshItem={onClickToFrigo} />
      ) : null}

      { stocksGroupBy === 0 && stocksView === 2 ? (
      <StocksByTypeAsTable
        items={items}
        stocksFilters={stocksFilters}
        openItemDetails={openModalItemDetails}
        openItemEdition={openModalItemEdition}
        openItemDeletion={openModalItemDeletion}
        editItem={onClickEdit}
        cloneItem={onClickClone}
        addInModels={onClickFavori}
        openItem={onClickToOpen}
        congelItem={onClickToCongel}
        freshItem={onClickToFrigo} />
      ) : null}



      { stocksGroupBy === 1 && stocksView !== 2 ? (
      <StocksByPlaceAsCards 
        items={items}
        stocksFilters={stocksFilters}
        openItemDetails={openModalItemDetails}
        openItemEdition={openModalItemEdition}
        openItemDeletion={openModalItemDeletion}
        editItem={onClickEdit}
        cloneItem={onClickClone}
        addInModels={onClickFavori}
        openItem={onClickToOpen}
        congelItem={onClickToCongel}
        freshItem={onClickToFrigo} />
      ) : null}

      { stocksGroupBy === 1 && stocksView === 2 ? (
      <StocksByPlaceAsTable 
        items={items}
        stocksFilters={stocksFilters}
        openItemDetails={openModalItemDetails}
        openItemEdition={openModalItemEdition}
        openItemDeletion={openModalItemDeletion}
        editItem={onClickEdit}
        cloneItem={onClickClone}
        addInModels={onClickFavori}
        openItem={onClickToOpen}
        congelItem={onClickToCongel}
        freshItem={onClickToFrigo} />
      ) : null}
      

      <div className="panel-contents-footer text-right">
        <Fab aria-label="Scanner" onClick={addScan} className="mh-2" color="primary"><CameraAltIcon /></Fab>
        <Fab aria-label="Ajouter" onClick={addSearchByName} className="mh-2" color="primary"><AddIcon /></Fab>
      </div>
      
    </Container>
  );
}

export default PanelStocks;
