// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';

// Composants DLC
import S_Items      from '../services/S_Items';
import {I_Day}      from '../services/I_Day';
import Item         from '../components/Item';

// Styles
// import './Day.scss';

type DayProps = {
  day: I_Day;
  isSmallScreen: boolean;
  openModalItemDetails: any;
  openModalItemEdition: any;
  openModalItemDeletion: any;
}

function Day(props:DayProps) {

  // props
  let {day,isSmallScreen,openModalItemDetails,openModalItemEdition,openModalItemDeletion} = props; 

  // state

  if(!day)
    return null;

  // autres variables
  let dateInText = (new Date(day.date)).toLocaleDateString("fr-FR", { year: undefined, month: undefined, day: undefined, weekday: 'long' });
  let dateInNumbers = (new Date(day.date)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined });
  let items = S_Items._getItemsofPrevisional(day.date);

  return (
    <Card className={'day ' + (isSmallScreen ? 'm-v-1 ' : '') + (day.past ? 'past ' : '') + (day.current ? 'current ' : '')} variant={day.past  ? 'outlined' : null}>
      <CardContent>
        <div className="flex">
          <div className="date text-center">
            <div className="dateInText"><strong>{dateInText}</strong></div>
            <div className="dateInNumbers">{dateInNumbers}</div>
          </div>
          <div className="contents">
            {items.map( (item,index) => (
              <Item key={index} 
                item={item} 
                onClick={(e) => openModalItemDetails(item)}
                onClickEdit={(e) => {e.stopPropagation();openModalItemEdition(item)}}
                onClickDelete={(e) => {e.stopPropagation();openModalItemDeletion(item)}}
                theme={'chip'}/>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default Day;
