import React,{useState,useEffect} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import StopIcon from '@material-ui/icons/Stop';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon   from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Composants génériques
// import S_App      from '../../services/S_App';
// import Scanner      from "../../elements/Scanner";
import S_Models     from '../services/S_Models';
import {I_Model}    from '../services/I_Model';
import Model        from '../components/Model';
import { Scanner } from 'react-mui-pwa-tools';

// Composants propres

// Styles
import './DialogScan.scss';

type DialogScanProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  createItemFromModel: any;
  createItemFromBarcode: any;
  editModel: any;
}

function DialogScan(props:DialogScanProps) {

  // props
  let {isDialogOpened,closeDialog,createItemFromModel,createItemFromBarcode,editModel} = props;

  // state
  const [barcode, setBarcode] = useState(undefined);
  const [models, setModels] = useState([]);
  const [verified, setVerified] = useState(false);

  // chargement des donnees
  useEffect(() => {
    console.log('-- DialogScan useEffect --');

    setBarcode(undefined);
    setModels([]);
    setVerified(false);
    
  }, []);

  const onDetected = result => {
    if(!!result){
      setBarcode(result);
    }
  };

  function verify(){
    let models = barcode!==undefined ? S_Models.getAllByBarcode(parseInt(barcode)) : [];
    console.log('verify barcode=',barcode,'models=',models);
    setModels(models);
    setVerified(true);
  }

  function close(){
    setBarcode(undefined);
    setModels([]);
    setVerified(false);
    closeDialog();
  }

  function createNew(){
    createItemFromBarcode(barcode);
    setBarcode(undefined);
    setModels([]);
    setVerified(false);
  }

  function createFromModel(model:I_Model){
    createItemFromModel(model);
    setBarcode(undefined);
    setModels([]);
    setVerified(false);
  }

  function reset(){
    setBarcode(undefined);
    setModels([]);
    setVerified(false);
  }


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Scanner un code barre</DialogTitle>
      <DialogContent>

        <Scanner 
          onDetected={onDetected} 
          onReset={reset} />
  
        {!!barcode ? (
          <div className="text-center">
            <h3>{barcode}</h3>
            {!verified ? (
              <Button variant="outlined" onClick={() => verify()} color="primary">Vérifier</Button>
            ) : null}
          </div>
        ) :null}

        {verified ? (
          <>
            {models.length>0 ? (
            <div className="mh-2">
              <p>Cliquez sur un modèle pour instancier un item</p>
              {models.map((model,index) => {
                return (
                  <div>
                    <Model key={index} 
                      model={model} 
                      onClick={(e) => createItemFromModel(model)}
                      onClickIcon={(e) => editModel(model)}
                      theme={'chip'}/>
                  </div>
                );
              })}
              <p>Non trouvé ?</p>
              <div>
                <Button variant="outlined" onClick={createNew} color="primary" startIcon={<AddIcon />} fullWidth>Créer un nouvel item</Button>
              </div>
            </div>
            ) : (
              <div className="mh-2">
                <p>Non trouvé ?</p>
                <div>
                  <Button variant="outlined" onClick={createNew} color="primary" startIcon={<AddIcon />} fullWidth>Créer un nouvel item</Button>
                </div>
              </div>
            )}

          </>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogScan;
