// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import KitchenIcon from '@material-ui/icons/Kitchen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import AcUnitIcon from '@material-ui/icons/AcUnit';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Models      from '../services/S_Models';
import S_Items      from '../services/S_Items';
import {I_Item}    from '../services/I_Item';
import Item         from '../components/Item';

// Styles
// import './StocksByPlace.scss';

type StocksByPlaceAsTableProps = {
  items: I_Item[];
  stocksFilters: number;    // 0 | 1 | 2

  openItemDetails: any;
  openItemEdition: any;
  openItemDeletion: any;

  editItem: any;
  cloneItem: any;
  addInModels: any;
  openItem: any;
  congelItem: any;
  freshItem: any;
}

function StocksByPlaceAsTable(props:StocksByPlaceAsTableProps) {

  // props
  let {items,stocksFilters,openItemDetails,openItemEdition,openItemDeletion,editItem,cloneItem,addInModels,openItem,congelItem,freshItem} = props;

  // state

  // autres variables
  let itemsOfNoPlace = S_App.getByFilters(items,[{attribute:'place',value:undefined}]);
  itemsOfNoPlace = S_Items.sortByExpirationDate(itemsOfNoPlace);

  return (
    <div className="panel-contents-content">
      <TableContainer>
        <Table size="small" aria-label="Liste des items">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Conservation</TableCell>
              <TableCell align="center">Création</TableCell>
              <TableCell align="center">Expiration</TableCell>
              <TableCell align="center">Congélation</TableCell>
              <TableCell align="center">Décongélation</TableCell>
              <TableCell align="center">Prévu pour</TableCell>
              <TableCell align="center">Ouverture</TableCell>
              <TableCell align="center">Stockage</TableCell>
            </TableRow>
          </TableHead>
          { S_Models.ALL_PLACES.map( (place,index) => {
            let itemsOfPlace = S_App.getByFilters(items,[{attribute:'place',value:place}]);
            itemsOfPlace = S_Items.sortByExpirationDate(itemsOfPlace);
            let PlaceIcon = place===S_Items.PLACE_FRIGO ? <KitchenIcon /> : (place===S_Items.PLACE_CONGEL ? <span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span> : <VerticalSplitIcon />);
            let PlaceLabel = place===S_Items.PLACE_FRIGO ? 'Au frigo' : (place===S_Items.PLACE_CONGEL ? 'Au congèl' : 'Au placard');
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="th" scope="row" colSpan={13}>
                    <h2>{place}</h2>
                  </TableCell>
                </TableRow>
                { itemsOfPlace.map((item,index) => {
                  // filtre created
                  let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(item.creationdate,(new Date()).getTime());
                  if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
                    return null;
                  return (
                    <Item key={index} 
                      item={item} 
                      onClick={(e) => openItemDetails(item)}
                      onClickEdit={editItem}
                      onClickDelete={(e) => {e.stopPropagation();openItemDeletion(item)}}
                      onClickClone={cloneItem}
                      onClickFavori={addInModels}
                      theme={'tableRow'}/>
                  );
                }) }
              </TableBody>
            );
          } ) }
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={12}>
                <h2>Sans lieu de stockage</h2>
              </TableCell>
            </TableRow>
            { itemsOfNoPlace.map((item,index) => {
              return (
                <Item key={index} 
                  item={item} 
                  onClick={(e) => openItemDetails(item)}
                  onClickEdit={editItem}
                  onClickDelete={(e) => {e.stopPropagation();openItemDeletion(item)}}
                  onClickClone={cloneItem}
                  onClickFavori={addInModels}
                  theme={'tableRow'}/>
              );
            }) }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default StocksByPlaceAsTable;
