// Composant version 2021
// ----------------------------------

import React,{useState,useEffect} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton,{ IconButtonProps } from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Tooltip from '@material-ui/core/Tooltip';
import KitchenIcon from '@material-ui/icons/Kitchen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import GradientIcon from '@material-ui/icons/Gradient';
import Code from '@material-ui/icons/Code';
import StarIcon from '@material-ui/icons/Star';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/core/styles';

// Composants DLC
import S_Items      from '../services/S_Items';
import {I_Item}      from '../services/I_Item';
import ItemDelay    from '../elements/ItemDelay';

// Styles
import './Item.scss';


type ItemProps = {
  item: I_Item;
  onClick?: any;    // function
  onClickEdit?: any;    // function
  onClickDelete?: any;    // function
  onClickClone?: any;
  onClickFavori?: any;
  onClickToOpen?: any;
  onClickToCongel?: any;
  onClickToFrigo?: any;
  theme?: string;  // tableRow, smallCard
}

function Item(props:ItemProps) {

  // props
  let {item,onClick,onClickEdit,onClickDelete,onClickClone,onClickFavori,onClickToOpen,onClickToCongel,onClickToFrigo,theme} = props;

  // state
  const [dynamicTheme, setDynamicTheme] = useState(theme);
  const [expanded, setExpanded] = useState(false);

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);

  function changeTheme(){
    if(dynamicTheme==='tableRow')
      return; // do nothing
    setDynamicTheme(dynamicTheme === 'smallCard' ? 'chip' : 'smallCard');
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  if(theme === 'tableRow')
    return (
      <TableRow onClick={onClick} className="clickable">
        <TableCell align="center">
          <IconButton aria-label="Modifier" size="small" onClick={onClickEdit}><EditIcon /></IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton aria-label="Supprimer" size="small" onClick={onClickDelete}><DeleteIcon /></IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{item.name}</TableCell>
        <TableCell align="center">{itemAdv.type ? <i className={'icon-'+itemAdv.type.svg}></i> : null}</TableCell>
        <TableCell align="center">{itemAdv.hasFreezeIcon ? <Tooltip title="Ne pas recongeler" className="freezeIcon"><span className="crossed"><AcUnitIcon /></span></Tooltip> : null}</TableCell>
        <TableCell align="center"><strong>{item.conservationtype ? item.conservationtype : '???'}</strong><br/>{item.conservationtype2 ? item.conservationtype2 : '???'}</TableCell>
        <TableCell align="center">{itemAdv.creationDateFormatted} <br/><i>({itemAdv.creationDelayFormatted})</i></TableCell>
        <TableCell align="center">{item.expirationtype ? item.expirationtype : 'DLC'} {itemAdv.expirationDateFormatted} <br/><i>({itemAdv.expirationDelayFormatted})</i></TableCell>
        <TableCell align="center">{itemAdv.congelationDateFormatted} {itemAdv.congelationDateFormatted ? <><br/><i>({itemAdv.congelationDelayFormatted})</i></> : null}</TableCell>
        <TableCell align="center">{itemAdv.decongelationDateFormatted} {itemAdv.decongelationDateFormatted ? <><br/><i>({itemAdv.decongelationDelayFormatted})</i></> : null}</TableCell>
        <TableCell align="center">{itemAdv.previsionalDateFormatted} {itemAdv.previsionalDateFormatted ? <><br/><i>({itemAdv.previsionalDelayFormatted})</i></> : null}</TableCell>
        <TableCell align="center">{itemAdv.openingDateFormatted} {itemAdv.openingDateFormatted ? <><br/><i>(Il y a {itemAdv.openingDelayInDays}j)</i></> : null}</TableCell>
        <TableCell align="center">{itemAdv.place}</TableCell>
      </TableRow>
    );

  if(dynamicTheme === 'smallCard')
    return (
      <Card className={'smallcard m-b-1 ' + itemAdv.colorFlag}>
        <CardHeader
          avatar={<Avatar onClick={changeTheme}>{itemAdv.type ? <i className={'icon-'+itemAdv.type.svg}></i> : null}</Avatar>}
          title={<h2 onClick={changeTheme}>{item.name}</h2>}
          subheader={
            <div onClick={changeTheme}>
            {item.barcode ? <div><Code />&nbsp;{item.barcode}</div> : null}
            {itemAdv.place && itemAdv.hasFreezeIcon ? <span className="spacer"></span> : null}
            {itemAdv.hasFreezeIcon ? <Tooltip title="Ne pas recongeler" className="freezeIcon"><span className="crossed"><AcUnitIcon /></span></Tooltip> : null} 
            </div>
          }
          action={<IconButton aria-label="Modifier" onClick={(e) => {setDynamicTheme(theme);onClickDelete(e);}}><DeleteIcon /></IconButton>}
        />
        <CardContent onClick={changeTheme} className="pt-0 pb-0">

          <div className="m-b-05" data-expirationdate={item.expirationdate}>
            <span className="icon-prefix"><strong>{item.expirationtype ? item.expirationtype : 'DLC'}</strong></span> 
            {itemAdv.expirationDateFormatted ? <>{itemAdv.expirationDateFormatted} <i>({itemAdv.expirationDelayFormatted}, {itemAdv.expirationDelayInDays}j)</i></> : <><i>Non défini</i></>}
          </div>

          {itemAdv.congelationDateFormatted ? (
          <dl className="m-b-05">
            <dt><span className="icon-prefix"><AcUnitIcon /></span><strong>Congélation</strong></dt>
            <dd>
              <div data-congelationdate={item.congelationdate}>
                le {itemAdv.congelationDateFormatted} 
                <small><i>({itemAdv.congelationDelayFormatted})</i> </small>
                {itemAdv.congelationDateFormatted && item.conservationtype===S_Items.PLACE_FRIGO ? <i>({itemAdv.congelationmaxtimeInDays - itemAdv.congelationDelayInDays}j)</i> : null}
                <br/><small><i>soit {itemAdv.gapBetweenExpirationAndCongelationInDays} jour(s) avant expiration</i></small>
                {item.conservationtype===S_Items.PLACE_FRIGO ? <><br/><small><i>Se conserve {itemAdv.congelationmaxtimeInMonths} mois au congèl</i></small></>: null}
              </div>
            </dd>
          </dl>
          ) : null}

          {itemAdv.decongelationDateFormatted ? (
          <dl className="m-b-05">
            <dt><span className="icon-prefix"><GradientIcon /></span>Décongélation</dt>
            <dd>
              <div data-decongelationdate={item.decongelationdate}>
                le {itemAdv.decongelationDateFormatted} 
                <small><i>({itemAdv.decongelationDelayFormatted})</i></small>
                <br/><small><i>{itemAdv.decongelationDelayFormatted2}</i></small>
              </div>
            </dd>
          </dl>
          ) : null}

          {itemAdv.openingDateFormatted ? (
          <dl className="m-b-05">
            <dt>
              <span className="icon-prefix"><RestaurantIcon /></span>
              <strong>Ouverture</strong>
            </dt>
            <dd>
              <div>
                Ouvert le {itemAdv.openingDateFormatted} 
                <small><i>(Il y a {itemAdv.openingDelayInDays} jour(s))</i></small>
              </div>
            </dd>
          </dl>
          ) : null}
          
        </CardContent>
        <CardActions>
          {onClickClone ? <IconButton aria-label="Cloner" onClick={(e) => onClickClone(item)}><FileCopyIcon /></IconButton>:null}
          <IconButton aria-label="Favori" onClick={(e) => {onClickFavori(item)}}>
            <StarIcon />
          </IconButton>
          { !item.openingdate && onClickToOpen ? (
          <IconButton aria-label="Ouvrir" onClick={(e) => onClickToOpen(item)}>
            <RestaurantIcon />
          </IconButton>
          ) : null }
          { !item.congelationdate && !item.decongelationdate && onClickToCongel ? (
          <IconButton aria-label="Mettre au congèl" onClick={(e) => onClickToCongel(item)}>
            <span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span>
          </IconButton>
          ) : null }
          { !!item.congelationdate && !item.decongelationdate && onClickToFrigo ? (
          <IconButton aria-label="Mettre au frigo (décongélation)" onClick={(e) => onClickToFrigo(item)}>
            <KitchenIcon />
          </IconButton>
          ) : null }
          <IconButton aria-label="Modifier" onClick={onClickEdit}><EditIcon /></IconButton>
          <IconButton aria-label="Plus" onClick={handleExpandClick}><ExpandMoreIcon /></IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div>
              <dl className="m-b-05">
                <dt>Se conserve au</dt>
                <dd>
                  <div data-conservationtype={item.conservationtype}><strong>{item.conservationtype ? item.conservationtype : '???'}</strong> avant ouverture</div>
                  <div data-conservationtype2={item.conservationtype2}><strong>{item.conservationtype2 ? item.conservationtype2 : '???'}</strong> après ouverture</div>
                </dd>
              </dl>

              <dl className="m-b-05">
                <dt>
                  <span className="icon-prefix">{itemAdv.place === S_Items.PLACE_FRIGO ? <Tooltip title="Au frigo"><KitchenIcon /></Tooltip> : ( itemAdv.place === S_Items.PLACE_CONGEL ? <Tooltip title="Au congèl"><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></Tooltip> : <Tooltip title="Au placard"><VerticalSplitIcon /></Tooltip>)}</span>
                  Est au <strong>{itemAdv.place === S_Items.PLACE_FRIGO ? 'frigo' : ( itemAdv.place === S_Items.PLACE_CONGEL ? 'congel' : 'placard')}</strong>
                </dt>
              </dl>

              <dl className="m-b-05">
                <dt>Catégorie</dt>
                <dd>
                  <div data-type={item.type}>{itemAdv.type ? <><i className={'icon-'+itemAdv.type.svg}></i> <strong>{itemAdv.type.name}</strong></> : null}</div>
                </dd>
              </dl>

              {itemAdv.previsionalDateFormatted ? (
              <div>Est prévu pour le <strong>{itemAdv.previsionalDateFormatted}</strong> <i>({itemAdv.previsionalDelayFormatted})</i></div>
              ) : null}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    );

  if(dynamicTheme === 'chip')
    return (
      <Chip onClick={changeTheme} className={'clickable m-05-r ' + itemAdv.colorFlag}
        avatar={<Avatar>{itemAdv.type ? <i className={'icon-'+itemAdv.type.svg}></i> : null}</Avatar>}
        label={
          <>
          {item.barcode!==undefined && item.barcode!==null ? <><Code/>&nbsp;</> : null}
          {item.name} 
          {itemAdv.place === S_Items.PLACE_FRIGO ? <Tooltip title="Au frigo"><KitchenIcon /></Tooltip> : ( itemAdv.place === S_Items.PLACE_CONGEL ? <Tooltip title="Au congèl"><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></Tooltip> : <Tooltip title="Au placard"><VerticalSplitIcon /></Tooltip>)}
          {itemAdv.hasFreezeIcon ? <Tooltip title="Ne pas recongeler" className="freezeIcon"><span className="crossed"><AcUnitIcon /></span></Tooltip> : null}
          {item.openingdate ? <Tooltip title="Entamé"><RestaurantIcon arial-label="Entamé"/></Tooltip> : null} 
          <ItemDelay item={item}/>
          {itemAdv.congelationDateFormatted && item.conservationtype===S_Items.PLACE_FRIGO ? <span className="delay">(<small><AcUnitIcon /></small>{itemAdv.congelationmaxtimeInDays - itemAdv.congelationDelayInDays}j)</span> : null}
          {itemAdv.previsionalDateFormatted ? <div className="previsional"><i>({itemAdv.previsionalDelayFormatted})</i></div> : null}
          </>
        }
        onDelete={onClickDelete}
        deleteIcon={<DeleteIcon />}
      />
    );

  return (
    <div className={'item'} onClick={onClick}>
      <div>creationdate : {item.creationdate}</div>
      <div>lastupdate : {item.lastupdate}</div>
      <div>expirationdate : {item.expirationdate}</div>
      <div>congelationdate : {item.congelationdate}</div>
      <div>decongelationdate : {item.decongelationdate}</div>
      <div>expirationtype : {item.expirationtype}</div>
      <div>conservationtype : {item.conservationtype}</div>
      <div>name : {item.name}</div>
      <div>type : {item.type}</div>

      <div>hasFreezeIcon : {itemAdv.hasFreezeIcon}</div>
      <div>place : {itemAdv.place}</div>
      <div>isInCongel : {itemAdv.isInCongel}</div>

      <div>expirationDateFormatted : {itemAdv.expirationDateFormatted}</div>
      <div>congelationDateFormatted : {itemAdv.congelationDateFormatted}</div>
      <div>decongelationDateFormatted : {itemAdv.decongelationDateFormatted}</div>

      <div>expirationDelayInDays : {itemAdv.expirationDelayInDays}</div>
      <div>congelationDelayInDays : {itemAdv.congelationDelayInDays}</div>
      <div>decongelationDelayInDays : {itemAdv.decongelationDelayInDays}</div>

      <div>expirationDelayFormatted : {itemAdv.expirationDelayFormatted}</div>
      <div>congelationDelayFormatted : {itemAdv.congelationDelayFormatted}</div>
      <div>decongelationDelayFormatted : {itemAdv.decongelationDelayFormatted}</div>

      <div>gapBetweenExpirationAndCongelationInDays : {itemAdv.gapBetweenExpirationAndCongelationInDays}</div>
      <div>decongelationDelayFormatted2 : {itemAdv.decongelationDelayFormatted2}</div>
      <div>colorFlag : {itemAdv.colorFlag}</div>
    </div>
  );
}

export default Item;
