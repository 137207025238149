// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Modal  from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

// Composants DLC
import S_Items      from '../services/S_Items';
import {I_Item}     from '../services/I_Item';

// Styles
import './Modal.scss';

type ModalItemDeletionProps = {
  item: I_Item;

  isModalOpened: boolean;
  closeModal: any;            // function close

  onConfirmDelete: any;        // function
}

function ModalItemDeletion(props:ModalItemDeletionProps) {

  // props
  let {item,isModalOpened,closeModal,onConfirmDelete} = props;

  // state

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);


  if(!item)
    return null;

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        <h2>Supprimer le produit "<strong>{item.name}</strong>" ?</h2>
        <br/>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" className="wide" onClick={closeModal}>Non</Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" startIcon={<DeleteIcon />} className="wide" onClick={onConfirmDelete}>Oui</Button>
          </Grid>
        </Grid>

      </div>
    </Modal>
  );
}

export default ModalItemDeletion;
