import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import AcUnitIcon from '@mui/icons-material/AcUnit';

// Composants App
import S_App        from '../../services/S_App';

// Composants DLC
import S_Models      from '../services/S_Models';

// Styles
import './Categories.scss';

interface CategoriesProps {
  initialstate: string;
  place: string;
  expirationtype: string;
  congelationtype: number;
}

function Categories(props:CategoriesProps) {

  // props
  let {initialstate, place, expirationtype, congelationtype} = props;

  const congelationtypeObject = congelationtype!==undefined ? S_App.getByID(S_Models.CONGELATION_TYPES,congelationtype) : undefined;

  const SVGIcon = <AcUnitIcon />;

  // si c'est pas deja surgele, et si on a une valeur de congelation max...
  const duree_de_congelation_max = initialstate!==S_Models.STATE_SURGELE && !!congelationtypeObject && congelationtypeObject.congelationmaxtime!==undefined ? (
    <>
      { congelationtypeObject.congelationmaxtime===0 ? <> - {SVGIcon} Ne pas congeler</> : null }
      { congelationtypeObject.congelationmaxtime>0 ? <> - {SVGIcon} {congelationtypeObject.congelationmaxtime} mois</> : null }
    </>
  ) : null;

  return (
    <div className="categories">
      {initialstate} - {place} - {expirationtype} {duree_de_congelation_max}
    </div>
  );
}

export default Categories;
