// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants DLC
import S_Items      from '../services/S_Items';
import {I_Item}      from '../services/I_Item';

// Styles
// import './ItemDelay.scss';

type ItemDelayProps = {
  item: I_Item;
}

function ItemDelay(props:ItemDelayProps) {

  // props
  let {item} = props;

  // state

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);

  // console.log(itemAdv);

  if(itemAdv.expirationDelayInDays===undefined || itemAdv.expirationDelayInDays===null)
    return null;

  return (
    <span className="delay">({item.expirationtype ? item.expirationtype : 'DLC'} {itemAdv.expirationDelayInDays}j)</span>
  );
}

export default ItemDelay;
