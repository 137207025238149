import S_App      from   '../../services/S_App';
// import {I_Day}    from   '../services/I_Day';

const A_DAY_IN_MILLIS = 24*60*60*1000;

let S_Semainier = {

  // renvoie le lundi de la semaine courante a minuit
  getMonday: () => {
    var date = new Date();
    var dayInWeek = date.getDay();
    var dateInMillis = date.getTime();
    // if(S_App.isLogDebug) console.log('-- S_Semainier.tsx -- getMonday()',date);
    var monday;
    if(dayInWeek !== 1){  // NB 0=sun 1=mon
      let deltaInMillis = (dayInWeek-1) * A_DAY_IN_MILLIS;
      monday = new Date(dateInMillis - deltaInMillis);
      // if(S_App.isLogDebug) console.log('-- S_Semainier.tsx -- getMonday() monday same hour:',monday);
    }else{
      monday = date;
    }
    if(monday){
      monday = new Date(monday.setHours(0,0,0,0));
      // if(S_App.isLogDebug) console.log('-- S_Semainier.tsx -- getMonday() monday midnight:',monday);
    }
    if(monday)
      return monday;
    return undefined;
  },

  // renvoie les semaines autour de la semaine courante, ou autour du monday en param
  getWeeks: (monday?:Date) =>{
    try{
      if(!monday)
        monday = S_Semainier.getMonday();
      if(!monday)
        return;

      let lastMondayInMillis = monday.getTime();
      // console.log('monday',monday);


      let currentWeek = [];
      for (let i = 0; i < 7; i++) {
        let deltaInMillis = i * A_DAY_IN_MILLIS;
        let date = new Date(lastMondayInMillis + deltaInMillis);
        currentWeek.push(S_Semainier.createDayObject(date));
      }
      // console.log('Current',currentWeek);

      let previousWeek = [];
      for (let i = 0; i < 7; i++) {
        let deltaInMillis = i * A_DAY_IN_MILLIS;
        let deltaWeekInMillis = 7 * A_DAY_IN_MILLIS;
        let date = new Date(lastMondayInMillis + deltaInMillis - deltaWeekInMillis);
        previousWeek.push(S_Semainier.createDayObject(date));
      }
      // console.log('Previous',previousWeek);

      let nextWeek = [];
      for (let i = 0; i < 7; i++) {
        let deltaInMillis = i * A_DAY_IN_MILLIS;
        let deltaWeekInMillis = 7 * A_DAY_IN_MILLIS;
        let date = new Date(lastMondayInMillis + deltaInMillis + deltaWeekInMillis);
        nextWeek.push(S_Semainier.createDayObject(date));
      }
      // console.log('Next',nextWeek);

      let weeks = {
        previous: previousWeek,
        current: currentWeek,
        next: nextWeek
      };
      // console.log('Weeks:',weeks);

      return weeks;
    }catch(err){
      console.error('-- S_Semainier.tsx -- getWeeks() : Cannot read weeks : ',err);
    }
  },


  createDayObject: (date:Date) => {
    let dayObject = {
      date: date,
      past: false,
      current: false
    };

    // current day
    var now = new Date();
    var currentDateInMillis = now.getTime();

    if(currentDateInMillis > (date.getTime() + A_DAY_IN_MILLIS))
      dayObject.past = true;

    if(currentDateInMillis > date.getTime() && currentDateInMillis <= (date.getTime() + A_DAY_IN_MILLIS))
      dayObject.current = true;

    // if(S_App.isLogDebug) console.log('-- S_Semainier.tsx -- createDayObject() :',dayObject,date,now);

    return dayObject;
  }
};
export default S_Semainier;