// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Models      from '../services/S_Models';
import S_Items      from '../services/S_Items';
import {I_Item}    from '../services/I_Item';
import Item         from '../components/Item';
import ItemAsCard    from '../components/ItemAsCard';

// Styles
// import './StocksByTypeAsCards.scss';

type StocksByTypeAsCardsProps = {
  items: I_Item[];
  stocksFilters: number;    // 0 | 1 | 2

  openItemDetails: any;
  openItemEdition: any;
  openItemDeletion: any;

  editItem: any;
  cloneItem: any;
  addInModels: any;
  openItem: any;
  congelItem: any;
  freshItem: any;
}

function StocksByTypeAsCards(props:StocksByTypeAsCardsProps) {

  // props
  let {items,stocksFilters,openItemDetails,openItemEdition,openItemDeletion,editItem,cloneItem,addInModels,openItem,congelItem,freshItem} = props;

  // state
  let DEFAULT_TYPES_OPENED = {};
  S_Items.TYPES.map( (type,index) => {
    DEFAULT_TYPES_OPENED[type.id] = false;
  })
  const [areTypesOpened, setAreTypesOpened] = useState(DEFAULT_TYPES_OPENED);    // on ferme par defaut les barquettes indiv. car utilisation moins frequente


  // autres variables
  let itemsOfNoType = S_App.getByFilters(items,[{attribute:'type',value:undefined}]);
  itemsOfNoType = S_Items.sortByExpirationDate(itemsOfNoType);

  function handleTypeClick(type){
    let id = type.id;
    setAreTypesOpened({
      ...areTypesOpened,
      [id]: !areTypesOpened[type.id]
    });
  }

  console.log({areTypesOpened});

  return (
    <div className="panel-contents-content">
      <Grid container className="mb-2">

        { /* par types */}
        { S_Items.TYPES.map( (type,index) => {
          let toggleButton = null;
          if(areTypesOpened[type.id])
            toggleButton = <span className="float-right">-</span>;
          if(!areTypesOpened[type.id])
            toggleButton = <span className="float-right">+</span>;

          let itemsOfType = S_App.getByFilters(items,[{attribute:'type',value:type.id}]);
          itemsOfType = S_Items.sortByExpirationDate(itemsOfType);

          let subtypes = type.subtypes;
          let itemsOfSubTypeUndefined = S_App.getByFilters(itemsOfType,[{attribute:'subtype',value:undefined}]);

          let filteredCount = 0;
          for(var i = 0; i < itemsOfType.length; i++){
            let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(itemsOfType[i].creationdate,(new Date()).getTime());
            if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
              continue;
            filteredCount++;
          }

          const Title = <h2>{type.name} ({stocksFilters!==0 ? <>{filteredCount} / </> : null}{itemsOfType.length}) {toggleButton}</h2>;

          return (
            <Grid item xs={12} md={6} lg={4} key={type.id}>
              <Card className="m-v-1">
                <CardHeader title={Title} onClick={(e) => handleTypeClick(type)} />
                { areTypesOpened[type.id] ? (
                <CardContent>

                  { /* par sous-types */}
                  {  subtypes.map( (subtype,index2) => {
                    let itemsOfSubType = S_App.getByFilters(itemsOfType,[{attribute:'subtype',value:subtype.id}]);
                    
                    if(itemsOfSubType===undefined || itemsOfSubType.length===0)
                      return null;

                    let sfilteredCount = 0;
                    for(var i = 0; i < itemsOfSubType.length; i++){
                      let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(itemsOfSubType[i].creationdate,(new Date()).getTime());
                      if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
                        continue;
                      sfilteredCount++;
                    }
                    const SubTitle = <span>{ subtype.name } ({stocksFilters!==0 ? <>{sfilteredCount} / </> : null}{itemsOfSubType.length})</span>;

                    return (
                      <div key={subtype.id} className="mb-1">
                        <div className="m-b-05">{SubTitle}</div>
                        <div>
                        { itemsOfSubType.map( (item,index3) => {
                          // filtre <1h
                          let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(item.creationdate,(new Date()).getTime());
                          if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
                            return null;
                          return (
                            <ItemAsCard key={item.id} 
                              item={item} 
                              onClick={(e) => openItemDetails(item)}
                              onClickEdit={editItem}
                              onClickDelete={() => openItemDeletion(item)}
                              onClickClone={cloneItem}
                              onClickFavori={addInModels}
                              onClickToOpen={openItem}
                              onClickToCongel={congelItem}
                              onClickToFrigo={freshItem} />
                          );
                        } ) }
                        </div>
                      </div>
                    );
                  }  )  }

                  { /* si pas de sous-types */}
                  {itemsOfSubTypeUndefined.length>0 ? (
                  <div className="clear">
                    <div className="m-b-05">Autres ({itemsOfSubTypeUndefined.length})</div>
                    <div>
                    { itemsOfSubTypeUndefined.map( (item,index2) => {
                      // filtre <1h
                      let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(item.creationdate,(new Date()).getTime());
                      if(stocksFilters !== 0 && gapBetweenNowAndCreationInHours > stocksFilters)
                        return null;
                      return (
                        <ItemAsCard key={item.id} 
                          item={item} 
                          onClick={(e) => openItemDetails(item)}
                          onClickEdit={editItem}
                          onClickDelete={() => openItemDeletion(item)}
                          onClickClone={cloneItem}
                          onClickFavori={addInModels}
                          onClickToOpen={openItem}
                          onClickToCongel={congelItem}
                          onClickToFrigo={freshItem} />
                      );
                    } ) }
                    </div>
                  </div>
                  ):null}
                </CardContent>
                ) : null }
              </Card>
            </Grid>
          );
        } ) }

        {itemsOfNoType.length > 0 ? (
        <Grid item xs={12} md={6} lg={4}>
          <Card className="m-v-1">
            <CardHeader
              title={<h2>Sans type ({itemsOfNoType.length})</h2>} />
            <CardContent>
              { itemsOfNoType.map((item,index2) => {
                return (
                  <ItemAsCard key={item.id} 
                    item={item} 
                    onClick={(e) => openItemDetails(item)}
                    onClickEdit={editItem}
                    onClickDelete={() => openItemDeletion(item)}
                    onClickClone={cloneItem}
                    onClickFavori={addInModels}
                    onClickToOpen={openItem}
                    onClickToCongel={congelItem}
                    onClickToFrigo={freshItem} />
                );
              }) }
            </CardContent>
          </Card>
        </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default StocksByTypeAsCards;
