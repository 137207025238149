// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import StarIcon from '@material-ui/icons/Star';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';

// Composants App
import S_App        from '../../services/S_App';

// Composants DLC
import S_Models      from '../services/S_Models';
import {I_Model}     from '../services/I_Model';
import Barcode       from '../elements/Barcode';
import Categories    from '../elements/Categories';

// Styles
import './ModelCard.scss';

type ModelCardProps = {
  model: I_Model;
  onClick?: any;
}

function ModelCard(props:ModelCardProps) {

  // props
  let {model,onClick} = props;

  // state

  // autres variables

  if(!model)
    return null;

  let congelationtypeObject = !!model.congelationtype ? S_App.getByID(S_Models.CONGELATION_TYPES,model.congelationtype) : undefined;

  return (
    <div className="modelCard">
      <div className="modelCardCell" onClick={(e) => onClick(model)}>
        <div className="star"><StarBorderPurple500OutlinedIcon /></div>
        <div className="content">
          <div className="name"><strong>{model.name}</strong></div>
          <Barcode 
            barcode={model.barcode} />
          <Categories 
            initialstate={model.initialstate}
            place={model.place}
            expirationtype={model.expirationtype}
            congelationtype={model.congelationtype} />
        </div>
      </div>
    </div>
  );
}

export default ModelCard;
