// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Fab       from '@mui/material/Fab';
import AddIcon   from '@mui/icons-material/Add';

// Composants génériques
import S_App        from '../../services/S_App';


// Composants DLC
import {I_Model}     from '../services/I_Model';
// import Model        from '../components/Model';
import ModelsAccordions from '../components/ModelsAccordions';
import ModelCardsList        from '../renderers/ModelCardsList';

// Styles
// import './PanelModels.scss';

type PanelModelsProps = {
  models: I_Model[];
  // openModalModelDetails: any;
  openModalModelEdition: any;
  // openModalModelDeletion: any;

  addModel: any;
}

function PanelModels(props:PanelModelsProps) {

  // props
  let {models,openModalModelEdition, addModel} = props;

  // state

  // chargement des donnees

  // autres variables
  let modelsOrderedByName = S_App.sortBy(models,'name');

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <h2 className="primary">Modèles par types</h2>
        <ModelsAccordions 
          models={modelsOrderedByName}
          openModalModelEdition={openModalModelEdition} />
      </div>

      {false ? (
      <div className="mt-vague">
        <h2 className="primary mb-2">Tous les modèles ({ modelsOrderedByName.length })</h2>
        <ModelCardsList
          models={modelsOrderedByName}
          onClick={openModalModelEdition} />
      </div>
      ) : null}
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addModel} className="mh-2" color="primary"><AddIcon /></Fab>
      </div>
      
    </Container>
  );
}

export default PanelModels;
