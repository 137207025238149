import React,{useState,useEffect} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import StopIcon from '@material-ui/icons/Stop';
import TextField from '@material-ui/core/TextField';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon   from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Composants génériques
// import S_App      from '../../services/S_App';
import S_Models     from '../services/S_Models';
import {I_Model}    from '../services/I_Model';
import Model        from '../components/Model';

// Composants propres

// Styles
// import './DialogSearchByName.scss';

type DialogSearchByNameProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  createItemFromModel: any;
  createItemFromName: any;
  editModel: any;
}

function DialogSearchByName(props:DialogSearchByNameProps) {

  // props
  let {isDialogOpened,closeDialog,createItemFromModel,createItemFromName,editModel} = props;

  // state
  const [name, setName] = useState('');
  const [models, setModels] = useState([]);

  // chargement des donnees
  useEffect(() => {
    setName('');
    setModels([]);
    
  }, []);

  function onSearched(){
    let models = !!name ? S_Models._getModelsMatchingName(name) : [];
    setModels(models);
  }

  function close(){
    setName('');
    setModels([]);
    closeDialog();
  }

  function changeName(value){
    setName(value);
    if(!!value && value.length>3){
      onSearched();
    }
  }


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Rechercher par nom</DialogTitle>
      <DialogContent>

        <p>Tapez au moins 4 caractères</p>
        <TextField id="name" label={'Nom'} type="text" fullWidth size="small" autoFocus={true}
          value={name}
          onChange={(e) => changeName(e.target.value)} />

        {models.length>0 ? (
          <div className="mh-2">
            <p>Cliquez sur un modèle pour instancier un item</p>
            {models.map((model,index) => {

              return (
                <div>
                  <Model key={index} 
                    model={model} 
                    onClick={(e) => createItemFromModel(model)}
                    onClickIcon={(e) => editModel(model)}
                    theme={'chip'}/>
                </div>
              );
            })}
            <p>Non trouvé ?</p>
            <div>
              <Button variant="outlined" onClick={(e) => createItemFromName(name)} color="primary" startIcon={<AddIcon />} fullWidth>Créer un nouvel item</Button>
            </div>
          </div>
        ) : (
          <div className="mh-2">
            <p>Non trouvé ?</p>
            <div>
              <Button variant="outlined" onClick={(e) => createItemFromName(name)} color="primary" startIcon={<AddIcon />} fullWidth>Créer un nouvel item</Button>
            </div>
          </div>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogSearchByName;
