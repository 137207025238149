// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Tooltip from '@material-ui/core/Tooltip';
import KitchenIcon from '@material-ui/icons/Kitchen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import GradientIcon from '@material-ui/icons/Gradient';
import Code from '@material-ui/icons/Code';
import StarIcon from '@material-ui/icons/Star';

// Composants DLC
import S_Models      from '../services/S_Models';
import S_Items      from '../services/S_Items';
import {I_Model}      from '../services/I_Model';

// Styles
// import './Model.scss';

type ModelProps = {
  model: I_Model;
  onClick?: any;    // function
  onClickIcon?: any;    // function
  theme?: string;  // chip
}

function Model(props:ModelProps) {

  // props
  let {model,onClick,onClickIcon,theme} = props;

  // state

  // autres variables
  let modelAdv = S_Models.getModelAdv(model);

  let placeIcon = model.conservationtype === S_Items.PLACE_FRIGO ? <Tooltip title="Au frigo"><KitchenIcon /></Tooltip> : ( model.conservationtype === S_Items.PLACE_CONGEL ? <Tooltip title="Au congèl"><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></Tooltip> : <Tooltip title="Au placard"><VerticalSplitIcon /></Tooltip>);
          

  return (
    <Chip onClick={onClick} className={'clickable m-05-r '}
      avatar={<Avatar><StarIcon /></Avatar>}
      label={
        <>
        {model.barcode!==undefined && model.barcode!==null ? <><Code/>&nbsp;</> : null}
        {model.name}
        {model.expirationtype ? <>&nbsp;- {model.expirationtype}</> : ''}
        {model.conservationtype ? <>&nbsp;{placeIcon}</> : null}
        </>
      }
      size="medium"
      onDelete={onClickIcon}
      deleteIcon={<EditIcon />}
    />
  );
}

export default Model;
