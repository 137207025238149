export const TYPES = [
  {
    'id':7,
    'name':'Barquettes individuelles',
    'subtypes': [
      {
        'id':1,
        'name':'Frais',
        'congelationmaxtime': 12
      },
      {
        'id':2,
        'name':'Equilibre',
        'congelationmaxtime': 12
      },
      {
        'id':3,
        'name':'Gras',
        'congelationmaxtime': 12
      }
    ]
  },
  {
    'id':4,
    'name':'Traiteur / Snacks',
    'svg': 'hamburger',
    'subtypes': [
    ]
  },
  {
    'id':1,
    'name':'Viandes / Poissons',
    'svg': 'meat',
    'subtypes': [
      {
        'id':1,
        'name':'Bœuf'
      },
      {
        'id':2,
        'name':'Veau'
      },
      {
        'id':3,
        'name':'Porc'
      },
      {
        'id':4,
        'name':'Mouton / Agneau'
      },
      {
        'id':5,
        'name':'Gibier'
      },
      {
        'id':6,
        'name':'Volaille'
      },
      {
        'id':7,
        'name':'Poisson'
      },
      {
        'id':8,
        'name':'Crustacés'
      },
      {
        'id':9,
        'name':'Veggies'
      }
    ]
  },
  {
    'id':6,
    'name':'Féculents',
    'svg': 'vegetables',
    'subtypes': [
      {
        'id':1,
        'name':'Riz'
      },
      {
        'id':2,
        'name':'Pomme de terre'
      },
      {
        'id':3,
        'name':'Blés'
      },
      {
        'id':5,
        'name':'Pois'
      }
    ]
  },
  {
    'id':2,
    'name':'Légumes',
    'svg': 'vegetables',
    'subtypes': [
      {
        'id':1,
        'name':'Primeur',
        'congelationmaxtime': 12
      },
      {
        'id':2,
        'name':'Traiteur',
        'congelationmaxtime': 12
      },
      {
        'id':3,
        'name':'Surgelés',
        'congelationmaxtime': 12
      },
      {
        'id':4,
        'name':'Conserves',
        'congelationmaxtime': 12
      },
      {
        'id':5,
        'name':'Soupes',
        'congelationmaxtime': 12
      }
    ]
  },
  {
    'id':3,
    'name':'Laitages',
    'svg': 'cheese',
    'subtypes': [
      {
        'id':1,
        'name':'Yaourts & Desserts',
        'congelationmaxtime': 3
      },
      {
        'id':2,
        'name':'Beurres & Crèmes',
        'congelationmaxtime': 3
      },
      {
        'id':3,
        'name':'Fromages',
        'congelationmaxtime': 3
      },
      {
        'id':4,
        'name':'Laits',
        'congelationmaxtime': 3
      }
    ]
  },
  {
    'id':5,
    'name':'Autres',
    'svg': undefined,
    'subtypes': [
      {
        'id':1,
        'name':'Boissons',
        'congelationmaxtime': 12
      }
    ]
  }
];