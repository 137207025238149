let S_Date = {

  formatDateForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  },

  formatDateInString(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatDateInStringWithName(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' });
    return null;
  },

  // renvoi le nb de jours restants
  formatDelayForNow(dateInMillis, reverse):number{
    if(!dateInMillis)
      return null;
    let ecart = dateInMillis - (new Date()).getTime();
    if(reverse)
      ecart = (new Date()).getTime() - dateInMillis;
    let days = ecart / 1000 / 60 / 60 / 24;
    return Math.round(days);
  },

  formatDateForMillis(dateInMaterial){
    if(!dateInMaterial)
      return undefined;
    // let year = parseInt(dateInMaterial.substr(0,4));
    // let month = parseInt(dateInMaterial.substr(6,8));
    // let day = parseInt(dateInMaterial.substr(10,12));
    return new Date(dateInMaterial);
  },

  formatDelayFrom(days:number):string{
    if(days===undefined)
      return;
    if(days>365){
      let nombreDAn = Math.round(days / 365);
      return 'Depuis + de '+nombreDAn+' ans';
    }
    if(days>30){
      let nombreDeMois = Math.round(days / 30);
      return 'Depuis + de '+nombreDeMois+' mois';
    }
    let result = '';
    if(days<=30){
      result = 'Depuis '+days+' jours';
    }
    if(days===1){
      result = 'Depuis hier';
    }
    if(days===0){
      result = "Depuis aujourd'hui";
    }
    if(days<0){
      result = "Incohérent";
    }
    return result;
  },

  formatDelayAgo(days:number):string{
    if(days===undefined)
      return;
    if(days>365){
      let nombreDAn = Math.round(days / 365);
      return 'Il y a + de '+nombreDAn+' ans';
    }
    if(days>30){
      let nombreDeMois = Math.round(days / 30);
      return 'Il y a + de '+nombreDeMois+' mois';
    }
    let result = '';
    if(days<=30){
      result = 'Il y a '+days+' jours';
    }
    if(days===1){
      result = 'Hier';
    }
    if(days===0){
      result = "Aujourd'hui";
    }
    if(days<0){
      result = "Incohérent";
    }
    return result;
  },

  

  daysBetween(dateInMillis1,dateInMillis2):number {
    if(dateInMillis2===undefined || dateInMillis1===undefined)
      return null;
    let gapInMillis = dateInMillis2 - dateInMillis1;
    return Math.round(gapInMillis / 1000 / 60 / 60 / 24);
  },
  hoursBetween(dateInMillis1,dateInMillis2):number {
    if(dateInMillis2===undefined || dateInMillis1===undefined)
      return null;
    let gapInMillis = dateInMillis2 - dateInMillis1;
    return Math.round(gapInMillis / 1000 / 60 / 60);
  }
    
  
};
export default S_Date;