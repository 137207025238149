// Composant version 2021
// ----------------------------------

import React, {useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Modal  from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Fab from '@material-ui/core/Fab';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import KitchenIcon from '@material-ui/icons/Kitchen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import GradientIcon from '@material-ui/icons/Gradient';
import StarIcon from '@material-ui/icons/Star';
import Snackbar from '@material-ui/core/Snackbar';

// Composants DLC
import S_Items      from '../services/S_Items';
import {I_Item}     from '../services/I_Item';

// Styles
import './Modal.scss';

type ModalItemDetailsProps = {
  item: I_Item;

  isModalOpened: boolean;
  closeModal: any;            // function close

  onClickDelete: any;        // function
  onClickEdit: any;        // function
  onClickToCongel: any;
  onClickToFrigo: any;
  onClickClone: any;
  onClickToOpen: any;
  onClickFavori: any;
}

function ModalItemDetails(props:ModalItemDetailsProps) {

  // props
  let {item,isModalOpened,closeModal,onClickDelete,onClickEdit,onClickToCongel,onClickToFrigo,onClickClone,onClickToOpen,onClickFavori} = props;

  // state
  const [snackbarFavoriOpened, setSnackbarFavoriOpened] = useState(false);

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarFavoriOpened(false);
  };


  if(!item)
    return null;

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        <div className="absolute-top-left">
          <Fab aria-label="Dupliquer" size="small" onClick={(e) => onClickClone(item)} title="Dupliquer"><FileCopyIcon /></Fab>
          <Fab aria-label="Favori" size="small" onClick={(e) => {onClickFavori(item);setSnackbarFavoriOpened(true)}} title="Favori"><StarIcon /></Fab>
          <Fab aria-label="Modifier" size="small" onClick={(e) => onClickEdit(item)} title="Modifier"><EditIcon /></Fab>

          <Snackbar 
            open={snackbarFavoriOpened} 
            autoHideDuration={3000} 
            onClose={handleCloseSnackbar}
            message={'Favori "'+item.name+'" créé !'} />
        </div>

        <div className="absolute-top-right">
          <Fab aria-label="Fermer" size="small" onClick={closeModal} title="Fermer la vue"><CloseIcon /></Fab>
        </div>

        <Card className={'m-v-2 ' + itemAdv.colorFlag}>
          <CardHeader
            avatar={<Avatar>{itemAdv.type ? <i className={'icon-'+itemAdv.type.svg}></i> : null}</Avatar>}
            title={<h2>{item.name}</h2>}
            subheader={
              <>
              {itemAdv.place === S_Items.PLACE_FRIGO ? <Tooltip title="Au frigo"><KitchenIcon /></Tooltip> : ( itemAdv.place === S_Items.PLACE_CONGEL ? <Tooltip title="Au congèl"><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></Tooltip> : <Tooltip title="Au placard"><VerticalSplitIcon /></Tooltip>)}
              {itemAdv.place && (itemAdv.hasFreezeIcon || item.openingdate) ? <span className="spacer"></span> : null}
              {itemAdv.hasFreezeIcon ? <Tooltip title="Ne pas recongeler" className="freezeIcon"><span className="crossed"><AcUnitIcon /></span></Tooltip> : null} 
              {itemAdv.hasFreezeIcon && item.openingdate ? <span className="spacer"></span> : null}
              {item.openingdate ? <Tooltip title="Entamé"><RestaurantIcon /></Tooltip> : null} 
              <span className="delay">({item.expirationtype ? item.expirationtype : 'DLC'} {itemAdv.expirationDelayInDays}j)</span>
              {itemAdv.congelationDateFormatted && item.conservationtype===S_Items.PLACE_FRIGO ? <span className="delay">(<small><AcUnitIcon /></small>{itemAdv.congelationmaxtimeInDays - itemAdv.congelationDelayInDays}j)</span> : null}
              </>
            }
          />
          <CardContent className="no-p-t">
            <dl className="m-b-05">
              <dt>Se conserve au</dt>
              <dd>
                <div data-conservationtype={item.conservationtype}><strong>{item.conservationtype ? item.conservationtype : '???'}</strong> avant ouverture</div>
                <div data-conservationtype2={item.conservationtype2}><strong>{item.conservationtype2 ? item.conservationtype2 : '???'}</strong> après ouverture</div>
              </dd>
            </dl>

            <div className="m-b-05">
              <div>
                Est au <strong>{itemAdv.place === S_Items.PLACE_FRIGO ? 'frigo' : ( itemAdv.place === S_Items.PLACE_CONGEL ? 'congel' : 'placard')}</strong>
              </div>
            </div>

            <dl className="m-b-05">
              <dt>Catégorie</dt>
              <dd>
                <div data-type={item.type}>
                  {itemAdv.type ? <><i className={'icon-'+itemAdv.type.svg}></i> <strong>{itemAdv.type.name}</strong></> : null}
                  {itemAdv.type && itemAdv.subtype ? <> - <span data-subtype={item.subtype}><strong>{itemAdv.subtype.name}</strong></span></> : null}
                </div>
                
              </dd>
            </dl>
            

            <div className="m-b-05">
              <hr/>
            </div>

            <div className="m-b-05" data-expirationdate={item.expirationdate}>
              <span className="icon-prefix"><strong>{item.expirationtype ? item.expirationtype : 'DLC'}</strong></span> 
              {itemAdv.expirationDateFormatted} <i>({itemAdv.expirationDelayFormatted})</i>
            </div>

            {itemAdv.congelationDateFormatted ? (
            <dl className="m-b-05">
              <dt><span className="icon-prefix"><AcUnitIcon /></span>Congélation</dt>
              <dd>
                <div data-congelationdate={item.congelationdate}>
                  le {itemAdv.congelationDateFormatted} <i>({itemAdv.congelationDelayFormatted})</i> 
                  <br/><small><i>soit {itemAdv.gapBetweenExpirationAndCongelationInDays} jour(s) avant expiration</i></small>
                  {item.conservationtype===S_Items.PLACE_FRIGO ? <><br/><small><i>Se conserve {itemAdv.congelationmaxtimeInMonths} mois au congèl (jusqu'au {itemAdv.expirationdate2Formatted})</i></small></>: null}
                </div>
              </dd>
            </dl>
            ) : null}

            {itemAdv.decongelationDateFormatted ? (
            <dl className="m-b-05">
              <dt><span className="icon-prefix"><GradientIcon /></span>Décongélation</dt>
              <dd>
                <div data-decongelationdate={item.decongelationdate}>
                  le {itemAdv.decongelationDateFormatted} <i>({itemAdv.decongelationDelayFormatted})</i>
                  <br/><small><i>{itemAdv.decongelationDelayFormatted2}</i></small>
                </div>
              </dd>
            </dl>
            ) : null}

            {itemAdv.openingDateFormatted ? (
            <div className="m-b-05">
                <div data-openingdate={item.openingdate}>
                  <span className="icon-prefix"><RestaurantIcon /></span> 
                  Ouvert le {itemAdv.openingDateFormatted} <i>(Il y a {itemAdv.openingDelayInDays} jour(s))</i>
                </div>
            </div>
            ) : null}
            
          </CardContent>
        </Card>

        <Card className={'m-b-2 '}>
          <CardContent>

            <h3>Planification</h3>

            {itemAdv.previsionalDateFormatted ? (
            <div>Est prévu pour le <strong>{itemAdv.previsionalDateFormatted}</strong> <i>({itemAdv.previsionalDelayFormatted})</i></div>
            ) : null}

          </CardContent>
        </Card>

        <Grid container spacing={2}>

          { !item.openingdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToOpen(item)}>Ouvrir</Button>
          </Grid>
          ) : null }

          { !item.congelationdate && !item.decongelationdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToCongel(item)}>Mettre au congèl</Button>
          </Grid>
          ) : null }

          { !!item.congelationdate && !item.decongelationdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToFrigo(item)}>Mettre au frigo (décongélation)</Button>
          </Grid>
          ) : null }

          <Grid item xs={12}>
            <Button variant="outlined" startIcon={<DeleteIcon />} className="wide" onClick={(e) => onClickDelete(item)}>Supprimer</Button>
          </Grid>
        </Grid>

      </div>
    </Modal>
  );
}

export default ModalItemDetails;
