export const CONGELATION_TYPES = [
  {
    'id': 1,
    'name':'Ne pas congeler',
    'congelationmaxtime': 0
  },
  {
    'id': 2,
    'name':'Déjà surgelé',
    'congelationmaxtime': undefined
  },
  {
    'id': 3,
    'name':'Bœuf, gibier, volaille (entier) : 8 mois',
    'congelationmaxtime': 8
  },
  {
    'id':4,
    'name':'Porc, agneau, veau, volaille (morceaux) : 6 mois',
    'congelationmaxtime': 6
  },
  {
    'id':5,
    'name':'Viande hachée : 3 mois',
    'congelationmaxtime': 3
  },
  {
    'id':6,
    'name':'Poisson, crustacés : 3 mois',
    'congelationmaxtime': 3
  },
  {
    'id':7,
    'name':'Légumes : 12 mois',
    'congelationmaxtime': 12
  },
  {
    'id':8,
    'name':'Laitages : 3 mois',
    'congelationmaxtime': 3
  }
];