import S_App    from   '../../services/S_App';
import S_Date   from   '../../services/S_Date';
import S_Models from   '../services/S_Models';
import {I_Item} from   '../services/I_Item';
import {TYPES} from   '../services/C_Types';

let S_Items = {
  STORAGE_KEY_ITEMS : 'items',

  items : [],

  PLACE_FRIGO : 'frigo',
  PLACE_CONGEL : 'congel',
  PLACE_ETAGERE : 'placard',
  CONSERVATIONTYPES : [
    'frigo',
    'congel',
    'placard'
  ],
  EXPIRATIONTYPES : [
    'DLC',
    'DLUO'
  ],
  TYPES : TYPES,
  DAYS : [
    {
      'id':1,
      'name':'Lundi'
    },
    {
      'id':2,
      'name':'Mardi',
    },
    {
      'id':3,
      'name':'Mercredi',
    },
    {
      'id':4,
      'name':'Jeudi',
    },
    {
      'id':5,
      'name':'Vendredi',
    },
    {
      'id':6,
      'name':'Samedi',
    },
    {
      'id':7,
      'name':'Dimanche',
    }
  ],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- fetch()...');
    var items = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Items.STORAGE_KEY_ITEMS];
    if(items){
      S_Items.items = JSON.parse(items);
    }else{
      S_Items.items = [];
    }
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- fetch() items',S_Items.items);
    // corrections de contenus
    let someChangesApplied = false;
    for(let item of S_Items.items){
      //
    }
    if(someChangesApplied){
      if(S_App.isLogDebug) console.log('-- S_Items.tsx -- fetch() items after corrections',S_Items.items);
      S_Items.store(() => {});
    }
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Items.STORAGE_KEY_ITEMS] = JSON.stringify(S_Items.items);
    // if(S_App.isLogDebug) console.log('-- S_Items.tsx -- store() items',S_Items.items); 
    if(cb)
      cb();
  },

  get: (id:number) => {
    return S_App.getByID(S_Items.items,id);
  },

  add(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- add()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationdate: undefined,
      congelationdate: undefined,
      decongelationdate: undefined,
      openingdate: undefined,
      previsionaldate: undefined,

      expirationtype: 'DLC',
      conservationtype: S_Items.PLACE_FRIGO,
      conservationtype2: S_Items.PLACE_FRIGO,

      name: '',
      type: undefined,
      subtype: undefined,

      barcode: undefined,
      initialstate: 'Frais',
      congelationtype: undefined
    };
    if(!S_Items.items)
      S_Items.items = [];
    S_Items.items.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- add() added',newOne);
    S_Items.store(() => {
      if(cb) cb(newOne);
    });
  },
  addInFrigo(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInFrigo()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationdate: currentDate,
      congelationdate: undefined,
      decongelationdate: undefined,
      openingdate: undefined,
      previsionaldate: undefined,

      expirationtype: 'DLC',
      conservationtype: S_Items.PLACE_FRIGO,
      conservationtype2: S_Items.PLACE_FRIGO,

      name: '',
      type: undefined,
      subtype: undefined,

      barcode: undefined,
      initialstate: 'Frais',
      congelationtype: undefined
    };
    if(!S_Items.items)
      S_Items.items = [];
    S_Items.items.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInFrigo() added',newOne);
    S_Items.store(() => {
      if(cb) cb(newOne);
    });
  },
  addInCongel(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInCongel()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationdate: undefined,
      congelationdate: currentDate,
      decongelationdate: undefined,
      openingdate: undefined,
      previsionaldate: undefined,

      expirationtype: 'DLC',
      conservationtype: S_Items.PLACE_CONGEL,
      conservationtype2: S_Items.PLACE_CONGEL,

      name: '',
      type: undefined,
      subtype: undefined,

      barcode: undefined,
      initialstate: 'Frais',
      congelationtype: undefined
    };
    if(!S_Items.items)
      S_Items.items = [];
    S_Items.items.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInCongel() added',newOne);
    S_Items.store(() => {
      if(cb) cb(newOne);
    });
  },
  addInEtagere(cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInEtagere()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationdate: undefined,
      congelationdate: currentDate,
      decongelationdate: undefined,
      openingdate: undefined,
      previsionaldate: undefined,

      expirationtype: 'DLC',
      conservationtype: S_Items.PLACE_ETAGERE,
      conservationtype2: S_Items.PLACE_ETAGERE,

      name: '',
      type: undefined,
      subtype: undefined,

      barcode: undefined,
      initialstate: 'Frais',
      congelationtype: undefined
    };
    if(!S_Items.items)
      S_Items.items = [];
    S_Items.items.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- addInEtagere() added',newOne);
    S_Items.store(() => {
      if(cb) cb(newOne);
    });
  },
  updateField(value,field,item:I_Item,cb){
    if(!S_Items.items || S_Items.items.length===0 || !item){
      if(cb) cb();
      return;
    }
     
    // if(S_App.isLogDebug) console.log('-- S_Items.tsx -- update() update',bain,'with',value,'on',field,'...'); 
    let foundData = undefined;
    for(var i = 0; i < S_Items.items.length; i++){
      if(S_Items.items[i].id !== item.id)
        continue;
      foundData = S_Items.items[i];
      if('type'===field || 'subtype'===field || 'barcode'===field || 'congelationtype'===field){
        foundData[field] = parseInt(value);
      }else if('creationdate'===field || 'lastupdate'===field || 'expirationdate'===field || 'openingdate'===field || 'congelationdate'===field || 'decongelationdate'===field || 'previsionaldate'===field){
        foundData[field] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Items.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Items.tsx -- update() updated',foundData);
    S_Items.store(() => {
      if(cb) cb(foundData);
    });
  },
  // si value est une date, doit etre au format material
  update(value,field,item:I_Item,cb){
    if(!S_Items.items || S_Items.items.length===0 || !item){
      if(cb) cb();
      return;
    }
     
    // if(S_App.isLogDebug) console.log('-- S_Items.tsx -- update() update',bain,'with',value,'on',field,'...'); 
    let foundData = undefined;
    for(var i = 0; i < S_Items.items.length; i++){
      if(S_Items.items[i].id !== item.id)
        continue;
      foundData = S_Items.items[i];
      if('type'===field || 'subtype'===field || 'barcode'===field || 'congelationtype'===field){
        foundData[field] = value!==undefined ? parseInt(value) : undefined;
      }else if('creationdate'===field || 'lastupdate'===field || 'expirationdate'===field || 'openingdate'===field || 'congelationdate'===field || 'decongelationdate'===field || 'previsionaldate'===field){
        foundData[field] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
      }else{
        foundData[field] = value;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_Items.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_Items.tsx -- update() updated',field,foundData);
    S_Items.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(item:I_Item,cb){
    if(!S_Items.items || S_Items.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- remove() delete',item,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_Items.items,item.id);
    if(foundIndex !== -1){
      S_Items.items.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Items.tsx -- remove() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Items.tsx -- remove() not found'); 
    }
    S_Items.store(() => {
      if(cb) cb();
    });
  },
  clone(item: I_Item,cb){
    if(!item){
      if(cb) cb();
      return;
    }
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      lastupdate: currentDate,
      creationdate: currentDate,

      expirationdate: item.expirationdate,
      openingdate: item.openingdate,
      congelationdate: item.congelationdate,
      decongelationdate: item.decongelationdate,
      previsionaldate: undefined,

      expirationtype: item.expirationtype,
      conservationtype: item.conservationtype,
      conservationtype2: item.conservationtype,

      name: item.name,
      type: item.type,
      subtype: item.subtype,

      barcode: item.barcode,
      initialstate: item.initialstate,
      place: item.place,
      congelationtype: item.congelationtype
    };
    if(!S_Items.items)
      S_Items.items = [];
    S_Items.items.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Items.tsx -- clone() added',newOne);
    S_Items.store(() => {
      if(cb) cb(newOne);
    });
  },



  getPlace: (item:I_Item) => {
    if(!item)
      return;

    if(item.openingdate && (!item.conservationtype2 || item.conservationtype2===S_Items.PLACE_FRIGO))
      return S_Items.PLACE_FRIGO;

    if(item.openingdate && (item.conservationtype2===S_Items.PLACE_CONGEL))
      return S_Items.PLACE_CONGEL;

    if(item.openingdate && (item.conservationtype2===S_Items.PLACE_ETAGERE))
      return S_Items.PLACE_ETAGERE;

    if(item.decongelationdate)
      return S_Items.PLACE_FRIGO;

    if(item.congelationdate)
      return S_Items.PLACE_CONGEL;

    if(item.expirationdate && (!item.conservationtype || item.conservationtype===S_Items.PLACE_FRIGO))
      return S_Items.PLACE_FRIGO;

    if(item.expirationdate && (item.conservationtype===S_Items.PLACE_CONGEL))
      return S_Items.PLACE_CONGEL;

    if(item.expirationdate && (item.conservationtype===S_Items.PLACE_ETAGERE))
      return S_Items.PLACE_ETAGERE;

    if(!item.conservationtype || item.conservationtype===S_Items.PLACE_FRIGO)
      return S_Items.PLACE_FRIGO;

    if(item.conservationtype===S_Items.PLACE_CONGEL)
      return S_Items.PLACE_CONGEL;

    return S_Items.PLACE_ETAGERE;
  },


  _getItemsIn: (type:string) => {
    if(!S_Items.items || S_Items.items.length===0)
      return [];

    let results = [];
    for(var i = 0; i < S_Items.items.length; i++){
      let item = S_Items.items[i];
      if(S_Items.getPlace(item) !== type)
        continue;
      results.push(item);
    }

    return S_Items.sortByExpirationDate(results);
  },

  _getItemsOfType: (typeID:number) => {
    if(!S_Items.items || S_Items.items.length===0)
      return [];

    let results = [];
    for(var i = 0; i < S_Items.items.length; i++){
      let item = S_Items.items[i];
      if(typeID!==undefined && typeID!==null){
        if(!item.type || item.type !== typeID)
          continue;
        results.push(item);
      }else{
        if(item.type!==undefined && item.type!==null && item.type!=='')
          continue;
        results.push(item);
      }
    }

    return S_Items.sortByExpirationDate(results);
  },

  _getItemsOfStatus: (status:string) => {
    if(!S_Items.items || S_Items.items.length===0)
      return [];

    let results = [];
    for(var i = 0; i < S_Items.items.length; i++){
      let item = S_Items.items[i];
      let itemStatus = S_Items.getStatus(item);
      if(status!==undefined && status!==null && status!==''){
        if(itemStatus !== status)
          continue;
        results.push(item);
      }else{
        if(itemStatus!==undefined && itemStatus!==null && itemStatus!=='')
          continue;
        results.push(item);
      }
    }

    return S_Items.sortByExpirationDate(results);
  },

  _getItemsofPrevisional: (date:Date) => {
    if(!S_Items.items || S_Items.items.length===0 || !date)
      return [];

    let nextDay = new Date(date.getTime() + (24*60*60*1000));

    let results = [];
    for(var i = 0; i < S_Items.items.length; i++){
      let item = S_Items.items[i];
      if(!item.previsionaldate || (item.previsionaldate < date.getTime() || item.previsionaldate > nextDay.getTime()))
        continue;
      results.push(item);
    }

    return results;
  },

  _filterItemsOfStatus: (items, statusArray) => {
    if(!items || items.length===0)
      return [];

    let results = [];
    for(var i = 0; i < items.length; i++){
      let item = items[i];
      let itemStatus = S_Items.getStatus(item);
      // console.log(itemStatus,statusArray.indexOf(itemStatus));
      if(!!itemStatus && statusArray.indexOf(itemStatus)===-1)
        continue;
      results.push(item);
    }

    return S_Items.sortByExpirationDate(results);
  },

  getItemAdv: (item) => {
    if(!item)
      return;
    
    let hasFreezeIcon = !!item.decongelationdate;
    let isInCongel = S_Items.getPlace(item) === S_Items.PLACE_CONGEL;
    let place = S_Items.getPlace(item);

    let creationDateFormatted = S_Date.formatDateInString(item.creationdate);
    let expirationDateFormatted = S_Date.formatDateInString(item.expirationdate);
    let openingDateFormatted = S_Date.formatDateInString(item.openingdate);
    let congelationDateFormatted = S_Date.formatDateInString(item.congelationdate);
    let decongelationDateFormatted = S_Date.formatDateInString(item.decongelationdate);
    let previsionalDateFormatted = S_Date.formatDateInStringWithName(item.previsionaldate);

    let creationDelayInDays = S_Date.formatDelayForNow(item.creationdate,true);
    let expirationDelayInDays = S_Date.formatDelayForNow(item.expirationdate,false);
    let openingDelayInDays = S_Date.formatDelayForNow(item.openingdate,true);
    let congelationDelayInDays = S_Date.formatDelayForNow(item.congelationdate,true);
    let decongelationDelayInDays = S_Date.formatDelayForNow(item.decongelationdate,true);
    let previsionalDelayInDays = S_Date.formatDelayForNow(item.previsionaldate,true);

    // Libelles
    let creationDelayFormatted = S_Date.formatDelayAgo(creationDelayInDays);
    let expirationDelayFormatted = S_Items.formatDelayTo(expirationDelayInDays);
    let openingDelayFormatted = S_Date.formatDelayAgo(openingDelayInDays);
    let congelationDelayFormatted = S_Date.formatDelayAgo(congelationDelayInDays);
    if(isInCongel)
      congelationDelayFormatted = S_Date.formatDelayFrom(congelationDelayInDays);
    let decongelationDelayFormatted = S_Date.formatDelayAgo(decongelationDelayInDays);
    let previsionalDelayFormatted = S_Items.formatDelayToPrevisional(-previsionalDelayInDays);

    let gapBetweenExpirationAndCongelationInDays = S_Date.daysBetween(item.congelationdate,item.expirationdate);

    let decongelationDelayFormatted2 = '';
    if(item.decongelationdate!==undefined && gapBetweenExpirationAndCongelationInDays!==undefined){
      let authorized = gapBetweenExpirationAndCongelationInDays / 2;
      if(decongelationDelayInDays > authorized){
        decongelationDelayFormatted2 = 'Consom. déconseillée';
      }else if(authorized - decongelationDelayInDays === 0){
        decongelationDelayFormatted2 = "A cons. aujourd'hui";
      }else if(authorized - decongelationDelayInDays === 1){
        decongelationDelayFormatted2 = 'A cons. avant demain';
      }else{
        decongelationDelayFormatted2 = 'A cons. avant ' + (authorized - decongelationDelayInDays) + 'j';
      }
    }

    let status = S_Items.getStatus(item);

    let typeObject = S_App.getByID(S_Items.TYPES,item.type);
    let subtypeObject = typeObject!==undefined ? S_App.getByID(typeObject.subtypes,item.subtype) : undefined;
    let dayObject = S_App.getByID(S_Items.DAYS,item.day);

    // temps max de congelation maison en mois
    let congelationmaxtimeInMonths = S_Items.getCongelationMaxTimeInMonth(item);

    // temps max de congelation maison en jours
    let congelationmaxtimeInDays = S_Items.getCongelationMaxTimeInDays(item);

    // depuis combien d'heures l'item a ete cree
    let gapBetweenNowAndCreationInHours = S_Date.hoursBetween(item.creationdate,(new Date()).getTime());

    // expiration de la congelation maison
    let expirationdate2 = S_Items.getExpirationDate_includingCongelation(item);
    let expirationdate2Formatted = expirationdate2!==undefined ? S_Date.formatDateInString(expirationdate2) : '';
      

    let itemAdv = {
      hasFreezeIcon: hasFreezeIcon,
      place: place,

      creationDateFormatted: creationDateFormatted,
      expirationDateFormatted: expirationDateFormatted,
      openingDateFormatted: openingDateFormatted,
      congelationDateFormatted: congelationDateFormatted,
      decongelationDateFormatted: decongelationDateFormatted,
      previsionalDateFormatted: previsionalDateFormatted,

      creationDelayInDays: creationDelayInDays,
      expirationDelayInDays: expirationDelayInDays,
      openingDelayInDays: openingDelayInDays,
      congelationDelayInDays: congelationDelayInDays,
      decongelationDelayInDays: decongelationDelayInDays,
      previsionalDelayInDays: previsionalDelayInDays,

      creationDelayFormatted: creationDelayFormatted,
      expirationDelayFormatted: expirationDelayFormatted,
      openingDelayFormatted: openingDelayFormatted,
      congelationDelayFormatted: congelationDelayFormatted,
      decongelationDelayFormatted: decongelationDelayFormatted,
      previsionalDelayFormatted: previsionalDelayFormatted,

      isInCongel: isInCongel,
      gapBetweenExpirationAndCongelationInDays: gapBetweenExpirationAndCongelationInDays,
      decongelationDelayFormatted2: decongelationDelayFormatted2,
      colorFlag: status,
      type: typeObject,
      subtype: subtypeObject,
      day: dayObject,
      congelationmaxtimeInMonths: congelationmaxtimeInMonths,
      congelationmaxtimeInDays: congelationmaxtimeInDays,
      gapBetweenNowAndCreationInHours: gapBetweenNowAndCreationInHours,
      expirationdate2: expirationdate2,
      expirationdate2Formatted: expirationdate2Formatted
    };

    return itemAdv;
  },

  // estce qu'il peut etre congele maison
  canBeCongeled(item:I_Item){
    return (item.initialstate!==S_Models.STATE_SURGELE) && (item.congelationtype!==undefined && item.congelationtype!==1 && item.congelationtype!==2);
  },
  // duree max de la congelation maison en mois
  getCongelationMaxTimeInMonth(item:I_Item){
    let congelationmaxtimeInMonths = undefined;
    // ancienne methode
    // if(typeObject!==undefined && item.conservationtype===S_Items.PLACE_FRIGO){
    //   congelationmaxtimeInMonths = typeObject.congelationmaxtime;
    //   if(item.type===1)
    //     congelationmaxtimeInMonths = subtypeObject!==undefined ? subtypeObject.congelationmaxtime : typeObject.congelationmaxtime;
    // }
    // nouvelle methode
    let congelationtypeObject = !!item.congelationtype ? S_App.getByID(S_Models.CONGELATION_TYPES,item.congelationtype) : undefined;
    if(!!congelationtypeObject && congelationtypeObject.congelationmaxtime!==undefined) 
      congelationmaxtimeInMonths = congelationtypeObject.congelationmaxtime;
    return congelationmaxtimeInMonths;
  },
  // duree max de la congelation maison en jours
  getCongelationMaxTimeInDays(item:I_Item){
    let congelationmaxtimeInDays = undefined;
    if(S_Items.canBeCongeled(item)){  // si congelable maison
      let congelationMaxTimeInMonth = S_Items.getCongelationMaxTimeInMonth(item);
      let congelationmaxtimeInDays = congelationMaxTimeInMonth!== undefined ? congelationMaxTimeInMonth * 30 : undefined;  // transform mois en jours
    }
    return congelationmaxtimeInDays;
  },

  // expiration de la congelation maison
  getExpirationDate_includingCongelation(item:I_Item){
    let expirationdate_includingCongelation = undefined;
    if(S_Items.canBeCongeled(item) && item.place===S_Models.PLACE_CONGEL){  // si congele maison
      let congelationMaxTimeInMonth = S_Items.getCongelationMaxTimeInMonth(item);
      let congelationMaxTimeInMillis = congelationMaxTimeInMonth!== undefined ? congelationMaxTimeInMonth * 30 * 24 * 60 * 60 * 1000 : undefined;  // transform mois en millis
      if(item.congelationdate!==undefined){
        expirationdate_includingCongelation = (new Date((new Date(item.congelationdate)).getTime() + congelationMaxTimeInMillis)).getTime();
      }
    }
    return expirationdate_includingCongelation;
  },

  // expiration generale (y compris congelation maison)
  getFinalExpirationInMillis(item:I_Item):number{
    let finalExpiration = undefined;

    // Si on a une expiration...
    if(item.expirationdate !== undefined) finalExpiration = item.expirationdate;

    // Si on a une congelation maison...
    let expirationDate_includingCongelation = S_Items.getExpirationDate_includingCongelation(item);
    if(expirationDate_includingCongelation !== undefined) finalExpiration = expirationDate_includingCongelation;

    return finalExpiration;
  },

  formatDelayTo(days:number):string{
    if(days===undefined)
      return;
    if(days>1){
      return 'Dans '+days+' jour(s)';
    }
    if(days===1){
      return 'Demain';
    }
    if(days===0){
      return "Aujourd'hui";
    }
    if(days<0){
      return "Périmé";
    }
  },
  formatDelayToPrevisional(days:number):string{
    if(days===undefined)
      return;
    if(days>1){
      return 'Dans '+days+' jour(s)';
    }
    if(days===1){
      return 'Demain';
    }
    if(days===0){
      return "Aujourd'hui";
    }
    if(days===-1){
      return "Hier";
    }
    if(days===-2){
      return "Avant-hier";
    }
    if(days<-2){
      return "Il y a "+days+" jour(s)";
    }
  },

  // status (couleur) :
  //   '' : non defini
  //   green : on a le temps
  //   orange : dans qq jours
  //   red : aujourd'hui
  //   grey : perime
  getStatus(item:I_Item):string{

    if(!item)
      return '';

    let finalExpiration = S_Items.getFinalExpirationInMillis(item);

    if(finalExpiration===undefined)
      return '';

    let status = '';

    let expirationDelayInDays = S_Date.formatDelayForNow(finalExpiration,false);
    if(expirationDelayInDays>2){
      status = 'green';
    }else if(expirationDelayInDays>1){
      status = 'orange';
    }else if(expirationDelayInDays===1){
      status = 'orange';
    }else if(expirationDelayInDays===0){
      status = 'red';  // perime aujourd'hui
    }else{
      status = 'grey';  // perime
    }

    // si decongelation, max la moitie du temps restant
    if(item.decongelationdate){
      let decongelationDelayInDays = S_Date.formatDelayForNow(item.decongelationdate,true);
      let gapBetweenExpirationAndCongelationInDays = S_Date.daysBetween(item.congelationdate,item.expirationdate);
      let authorized = gapBetweenExpirationAndCongelationInDays / 2;
      if(decongelationDelayInDays <= authorized){
        status = 'blue';  
      }else{
        status = 'grey';  // perime
      }
    }

    return status;
  },


  sortByExpirationDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      // let advA = S_Items.getItemAdv(a);
      // let advB = S_Items.getItemAdv(b);
      // let lastDateA = advA.expirationdate2 !== undefined ? advA.expirationdate2 : (a.expirationdate !== undefined ? a.expirationdate : a.name.toLowerCase());
      // let lastDateB = advB.expirationdate2 !== undefined ? advB.expirationdate2 : (b.expirationdate !== undefined ? b.expirationdate : b.name.toLowerCase());


      let lastDateA = S_Items._getFinalExpirationValue(a);
      let lastDateB = S_Items._getFinalExpirationValue(b);


      //console.log('sortByExpirationDate',a,b,lastDateA,lastDateB);

      return lastDateA < lastDateB ? -1 : 1;
    });
    return arrayOrdered;
  },
  _getFinalExpirationValue(item:I_Item){
    let finalValue = item.name.toLowerCase();

    // Si c'est du primeur
    if(item.initialstate===S_Models.STATE_FRAIS && item.expirationdate === undefined) finalValue = '0_'+item.name.toLowerCase();

    // Si on a une expiration...
    if(item.expirationdate !== undefined) finalValue = item.expirationdate+'';

    // Si on a une congelation maison...
    // if(advA.expirationdate2 !== undefined) finalValue = advA.expirationdate2;
    let expirationDate_includingCongelationA = S_Items.getExpirationDate_includingCongelation(item);
    if(expirationDate_includingCongelationA !== undefined) finalValue = expirationDate_includingCongelationA;

    return finalValue;
  }

};
export default S_Items;