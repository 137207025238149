// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Composants génériques

// Composants DLC
// import S_Items      from '../services/S_Items';
import S_Semainier  from '../services/S_Semainier';
import Day          from '../components/Day';

// Styles
// import './PanelSemainier.scss';

type PanelSemainierProps = {
  isSmallScreen: boolean;
  openModalItemDetails: any;
  openModalItemEdition: any;
  openModalItemDeletion: any;
}

function PanelSemainier(props:PanelSemainierProps) {

  // props
  let {isSmallScreen,openModalItemDetails,openModalItemEdition,openModalItemDeletion} = props;

  // state
  const [displayedWeek, setDisplayedWeek] = useState(0);

  // autres variables
  const weeks = S_Semainier.getWeeks();
  const currentWeek = weeks.current;
  const nextWeek = weeks.next;
  const previousWeek = weeks.previous; 



  return (
    <Container fixed className="panel-contents">

      {isSmallScreen ? (
      <Grid container className="p-v-1">
        <Grid item xs={12} className="text-center">
          <ButtonGroup aria-label="Semaine">
            <Button variant={displayedWeek === -1 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setDisplayedWeek(-1)} size="small" title="Semaine précédente">Semaine précédente</Button>
            <Button variant={displayedWeek === 0 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setDisplayedWeek(0)} size="small" title="Semaine courante">Semaine courante</Button>
            <Button variant={displayedWeek === 1 ? 'contained' : 'outlined'} disableElevation onClick={(e) => setDisplayedWeek(1)} size="small" title="Semaine prochaine">Semaine prochaine</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      ) : null}

      {isSmallScreen && displayedWeek === -1 ? (
      <div className="previousWeek">
        {previousWeek.map( (weekDateObject,index) => (
          <Day key={index}
            day={weekDateObject}
            isSmallScreen={isSmallScreen}
            openModalItemDetails={openModalItemDetails}
            openModalItemEdition={openModalItemEdition}
            openModalItemDeletion={openModalItemDeletion} />
        ))}
      </div>
      ) : null}

      {isSmallScreen && displayedWeek === 0 ? (
      <div className="currentWeek">
        {currentWeek.map( (weekDateObject,index) => (
          <Day key={index}
            day={weekDateObject}
            isSmallScreen={isSmallScreen}
            openModalItemDetails={openModalItemDetails}
            openModalItemEdition={openModalItemEdition}
            openModalItemDeletion={openModalItemDeletion} />
        ))}
      </div>
      ) : null}

      {isSmallScreen && displayedWeek === 1 ? (
      <div className="nextWeek">
        {nextWeek.map( (weekDateObject,index) => (
          <Day key={index}
            day={weekDateObject}
            isSmallScreen={isSmallScreen}
            openModalItemDetails={openModalItemDetails}
            openModalItemEdition={openModalItemEdition}
            openModalItemDeletion={openModalItemDeletion} />
        ))}
      </div>
      ) : null}


      {!isSmallScreen ? (
      <TableContainer>
        <Table size="small" aria-label="Semainier" className="table-semainier">
          <TableHead>
            <TableRow>
              <TableCell align="center" component="th" scope="row"><strong>Semaine précédente</strong></TableCell>
              <TableCell align="center"><strong>Semaine courante</strong></TableCell>
              <TableCell align="center"><strong>Semaine prochaine</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { [0,1,2,3,4,5,6].map( (dayIndex,index) => {
            let weekDateObject1 = currentWeek[dayIndex];
            let weekDateObject2 = nextWeek[dayIndex];
            let weekDateObject3 = previousWeek[dayIndex];

            return (
              <TableRow key={index}>
                <TableCell>
                  <Day key={index}
                    day={weekDateObject3}
                    isSmallScreen={isSmallScreen}
                    openModalItemDetails={openModalItemDetails}
                    openModalItemEdition={openModalItemEdition}
                    openModalItemDeletion={openModalItemDeletion} />
                </TableCell>
                <TableCell>
                  <Day key={index}
                    day={weekDateObject1}
                    isSmallScreen={isSmallScreen}
                    openModalItemDetails={openModalItemDetails}
                    openModalItemEdition={openModalItemEdition}
                    openModalItemDeletion={openModalItemDeletion} />
                </TableCell>
                <TableCell>
                  <Day key={index}
                    day={weekDateObject2}
                    isSmallScreen={isSmallScreen}
                    openModalItemDetails={openModalItemDetails}
                    openModalItemEdition={openModalItemEdition}
                    openModalItemDeletion={openModalItemDeletion} />
                </TableCell>
              </TableRow>
            );
          } ) }
          </TableBody>
        </Table>
      </TableContainer>
      ) : null}

    </Container>
  );
}

export default PanelSemainier;
