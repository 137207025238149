// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import IconButton,{ IconButtonProps } from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Code from '@material-ui/icons/Code';
import StarIcon from '@material-ui/icons/Star';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KitchenIcon from '@material-ui/icons/Kitchen';
import GradientIcon from '@material-ui/icons/Gradient';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';


// Composants App
import S_App        from '../../services/S_App';
import S_Date       from '../../services/S_Date';

// Composants DLC
import S_Models     from '../services/S_Models';
import S_Items      from '../services/S_Items';
import {I_Item}     from '../services/I_Item';
import ItemDelay    from '../elements/ItemDelay';

// Styles
import './ItemAsCard.scss';


type ItemAsCardProps = {
  item: I_Item;
  onClick?: any;    // function
  onClickEdit?: any;    // function
  onClickDelete?: any;    // function
  onClickClone?: any;
  onClickFavori?: any;
  onClickToOpen?: any;
  onClickToCongel?: any;
  onClickToFrigo?: any;
  theme?: string;  // tableRow, smallCard
}

function ItemAsCard(props:ItemAsCardProps) {

  // props
  let {item,onClick,onClickEdit,onClickDelete,onClickClone,onClickFavori,onClickToOpen,onClickToCongel,onClickToFrigo,theme} = props;

  // state
  const [expanded, setExpanded] = useState(false);

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onClickDelete();
  };

  // est congele : est au congel
  let isCongeled = item.place===S_Models.PLACE_CONGEL;
  // est decongele :  a une date de decongelation, ou est un surgele qui n'est plus au congel
  let isDecongeled = !!item.decongelationdate || (item.initialstate===S_Models.STATE_SURGELE && item.place!==S_Models.PLACE_CONGEL);
  // est conserve
  let isConserve = item.initialstate===S_Models.STATE_CONSERVE && (item.place===S_Models.PLACE_COMPTOIR || item.place===S_Models.PLACE_CELLIER || item.place===S_Models.PLACE_PLACARD);

  // on peut le mettre au congel, sauf si il est deja au congel, ou si il a deja ete congele, ou si il ne doit pas y aller
  let canPutInCongel = !isDecongeled && (item.place!==S_Models.PLACE_CONGEL) && (item.congelationtype!==1 && item.congelationtype!==2) && onClickToCongel;
  // on peut le mettre au frigo, sauf si il y est deja
  let canPutInFrigo = (item.place!==S_Models.PLACE_FRIGO) && onClickToFrigo;
  // on peut l'ouvrir, sauf si il est deja ouvert
  let canBeOpened = !item.openingdate && onClickToOpen;



  let congelationtypeObject = !!item.congelationtype ? S_App.getByID(S_Models.CONGELATION_TYPES,item.congelationtype) : undefined;

  let finalExpirationDate = S_Items.getExpirationDate_includingCongelation(item);

  let NOW = (new Date()).getTime();

  console.log('congelationtypeObject',congelationtypeObject);
  console.log('canBeCongeled',S_Items.canBeCongeled(item));
  console.log('getCongelationMaxTimeInMonth',S_Items.getCongelationMaxTimeInMonth(item));

  console.log('finalExpirationDate',finalExpirationDate);


  return (
    <div className={'itemAsCard '+itemAdv.colorFlag + (isDecongeled?' isDecongeled':'')}>

      <div className={'chip'} onClick={handleExpandClick}>
        { isDecongeled ? <span className="isDecongeled"><AcUnitIcon /><span>décongelé</span></span> : null }
        { isCongeled && !isDecongeled ? <span className="isCongeled"><AcUnitIcon /><span>{ item.initialstate===S_Models.STATE_SURGELE ? 'surgelé' : 'congelé' }</span></span> : null }
        { isConserve ? <span className="isConserve"><CorporateFareIcon /><span>conserve</span></span> : null }
        <div className="float-right action">
          <IconButton aria-label="Supprimer" onClick={handleDeleteClick} size="small"><DeleteIcon /></IconButton>
        </div>
        <div>
          <span className="name">{item.name}</span> 
          <ItemDelay item={item}/>
          {!!finalExpirationDate ? <span className="delay">(<small><AcUnitIcon /></small>{S_Date.daysBetween(NOW,finalExpirationDate)}j)</span> : null}
        </div>
        <div>
          <small>
            {item.initialstate} - {item.place}
            {itemAdv.type ? <> - {itemAdv.type.name}</> : <> - Type non renseigné</>}
            {item.openingdate ? <> - <Tooltip title="Entamé"><RestaurantIcon arial-label="Entamé"/></Tooltip></> : null} </small>
        </div>
      </div>

      {itemAdv.previsionalDateFormatted ? <div className="previsional"><i>({itemAdv.previsionalDelayFormatted})</i></div> : null}

      {expanded ? (
      <div className={'under'}>
        {item.barcode!==undefined && item.barcode!==null ? <div className="barcode"><Code/>{item.barcode}</div> : <div className="barcode unknown"><Code/> Non défini</div>}

        <div className="m-b-05" data-expirationdate={item.expirationdate}>
          <span className="icon-prefix"><strong>{item.expirationtype ? item.expirationtype : 'DLC'}</strong></span> 
          {itemAdv.expirationDateFormatted ? <>{itemAdv.expirationDateFormatted} <i>({itemAdv.expirationDelayFormatted}, {itemAdv.expirationDelayInDays}j)</i></> : <><i>Non défini</i></>}
        </div>

        {isCongeled || isDecongeled || canPutInCongel ? (
        <dl className="m-b-05">
          <dt><span className="icon-prefix"><AcUnitIcon /></span><strong>Congélation</strong></dt>
          <dd>
            {(isCongeled || isDecongeled) && itemAdv.congelationDateFormatted ? (
            <div data-congelationdate={item.congelationdate}>
              le {itemAdv.congelationDateFormatted} 
              <small><i>({itemAdv.congelationDelayFormatted})</i> </small>
              <br/><small><i>soit {itemAdv.gapBetweenExpirationAndCongelationInDays} jour(s) avant expiration</i></small>
            </div>
            ) : (
            <div className="unknown">Date non renseignée</div>
            )}

            { item.initialstate!==S_Models.STATE_SURGELE && !!congelationtypeObject ? (
            <div>
              <small><i>
              { congelationtypeObject.congelationmaxtime!==undefined && congelationtypeObject.congelationmaxtime===0 ? 'Ne se conserve pas au congélateur' : null }
              { congelationtypeObject.congelationmaxtime!==undefined && congelationtypeObject.congelationmaxtime>0 ? 'Se conserve ' + congelationtypeObject.congelationmaxtime + '  mois au congélateur' : null }
              </i></small>
            </div>
            ) : null}

            {finalExpirationDate ? (
            <div><i>{S_Date.daysBetween(NOW,finalExpirationDate)}j avant expiration de la congélation maison</i></div>
            ) : null}
          </dd>
        </dl>
        ) : null}

        {isDecongeled ? (
        <dl className="m-b-05">
          <dt><span className="icon-prefix"><GradientIcon /></span><strong>Décongélation</strong></dt>
          <dd>
            { itemAdv.decongelationDateFormatted ? (
            <div data-decongelationdate={item.decongelationdate}>
              le {itemAdv.decongelationDateFormatted} 
              <small><i>({itemAdv.decongelationDelayFormatted})</i></small>
              <br/><small><i>{itemAdv.decongelationDelayFormatted2}</i></small>
            </div>
            ) : (
            <div className="unknown">Date non renseignée</div>
            ) }
          </dd>
        </dl>
        ) : null}

        {itemAdv.openingDateFormatted ? (
        <dl className="m-b-05">
          <dt>
            <span className="icon-prefix"><RestaurantIcon /></span>
            <strong>Ouverture</strong>
          </dt>
          <dd>
            <div>
              Ouvert le {itemAdv.openingDateFormatted} 
              <small><i>(Il y a {itemAdv.openingDelayInDays} jour(s))</i></small>
            </div>
          </dd>
        </dl>
        ) : null}

        {itemAdv.previsionalDateFormatted ? (
        <div>Est prévu pour le <strong>{itemAdv.previsionalDateFormatted}</strong> <i>({itemAdv.previsionalDelayFormatted})</i></div>
        ) : null}

        { onClickClone ? <IconButton aria-label="Cloner" onClick={(e) => onClickClone(item)}><FileCopyIcon /></IconButton> : <IconButton aria-label="Cloner" disabled={true}><FileCopyIcon /></IconButton>}
        { onClickFavori ? <IconButton aria-label="Ajouter aux favoris" onClick={(e) => {onClickFavori(item)}}><StarIcon /></IconButton> : <IconButton aria-label="Ajouter aux favoris" disabled={true}><StarIcon /></IconButton> }
        { canBeOpened ? <IconButton aria-label="Ouvrir" onClick={(e) => onClickToOpen(item)}><RestaurantIcon /></IconButton> : <IconButton aria-label="Ouvrir" disabled={true}><RestaurantIcon /></IconButton> }
        { canPutInCongel ? <IconButton aria-label="Mettre au congèl" onClick={(e) => onClickToCongel(item)}><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></IconButton> : <IconButton aria-label="Mettre au congèl" disabled={true}><span className="combinedIcons"><KitchenIcon /><AcUnitIcon /></span></IconButton> }
        { canPutInFrigo ? <IconButton aria-label="Mettre au frigo" onClick={(e) => onClickToFrigo(item)}><KitchenIcon /></IconButton> : <IconButton aria-label="Mettre au frigo" disabled={true}><KitchenIcon /></IconButton> }
        { onClickEdit ? <IconButton aria-label="Modifier" onClick={(e) => onClickEdit(item)}><EditIcon /></IconButton> : <IconButton aria-label="Modifier" disabled={true}><EditIcon /></IconButton> }
        
      </div>
      ) : null}

    </div>
  );
}

export default ItemAsCard;
