// Composant version 2021
// ----------------------------------

import React, {useState,useRef} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Modal  from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StarIcon from '@material-ui/icons/Star';
import Snackbar from '@material-ui/core/Snackbar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import StopIcon from '@material-ui/icons/Stop';
import Paper  from '@material-ui/core/Paper';

import ToggleButton      from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';
// import Scanner    from "../../elements/Scanner";
import { Scanner } from 'react-mui-pwa-tools';

// Composants DLC
import S_Items      from '../services/S_Items';
import S_Models     from '../services/S_Models';
import {I_Item}     from '../services/I_Item';
import Model        from '../components/Model';

// Styles
import './Modal.scss';

type ModalItemEditionProps = {
  item: I_Item;

  isModalOpened: boolean;
  closeModal: any;            // function close

  onFieldChange: any;         // function change value
  onClickDelete: any;        // function
  onClickToCongel: any;
  onClickToFrigo: any;
  onClickClone: any;
  onClickToOpen: any;
  onClickFavori: any;
  copyFromModel: any;
}

function ModalItemEdition(props:ModalItemEditionProps) {

  // props
  let {item,isModalOpened,closeModal,onFieldChange,onClickDelete,onClickToCongel,onClickToFrigo,onClickClone,onClickToOpen,onClickFavori,copyFromModel} = props;

  // state
  const [snackbarFavoriOpened, setSnackbarFavoriOpened] = useState(false);
  const [matchingModels, setMatchingModels] = useState([]);
  const [matchingModelsBarcode, setMatchingModelsBarcode] = useState([]);
  const [camera, setCamera] = useState(false);

  const [name, setName] = useState(item ? item.name : '');
  const [barcode, setBarcode] = useState(item ? item.barcode : undefined);
  const [place, setPlace] = useState(item ? item.place : S_Models.PLACE_FRIGO);
  const [initialstate, setInitialstate] = useState(item ? item.initialstate : 'Frais');
  const [congelationtype, setCongelationtype] = useState(item ? item.congelationtype : undefined);


  // refs
  let fieldName =  null;
  let fieldBarcode = useRef(null);

  if(!item)
    return null;

  // console.log('item',item);

  // autres variables
  let itemAdv = S_Items.getItemAdv(item);
  let itemType = S_App.getByID(S_Items.TYPES,item.type);
  let subtypes = item.type!==undefined && itemType!==undefined ? itemType.subtypes : [];
  // console.log('itemType',itemType);

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarFavoriOpened(false);
  };

  const handleChangeName = (value) => {
    setName(value);
    onFieldChange(value,'name',item);

    // update suggestions
    if(value.length<=2){
      setMatchingModels([]);
    }else{
      setMatchingModels(S_Models._getModelsMatchingName(value));
    }
  };

  const handleChangeBarcode = (value) => {
    setBarcode(value);
    onFieldChange(value,'barcode',item);

    if(value.length<=2){
      setMatchingModels([]);
    }else{
      let matchs = S_Models._getModelsMatchingBarcode(parseInt(value));
      if(matchs.length>0){
        // on charge automatiquement le premier
        copyFromModel(matchs[0]);
        if(matchs.length>1){
          // on affiche les suggestions
          setMatchingModelsBarcode(matchs);
        }
      }
    }
  };

  const onDetected = result => {
    if(!!result){
      setCamera(false);

      if(!!fieldBarcode && !!fieldBarcode.current){
        fieldBarcode.current.value = result;
        fieldBarcode.current.focus();
      }

      onFieldChange(result,'barcode',item);
      setBarcode(result);

      setMatchingModelsBarcode([]);
      if((result+'').length>2){
        let matchs = S_Models._getModelsMatchingBarcode(parseInt(result));
        if(matchs.length>0){
          // on charge automatiquement le premier
          copyFromModel(matchs[0]);
          if(matchs.length>1){
            // on affiche les suggestions
            setMatchingModelsBarcode(matchs);
          }
        }
      }
    }
  };

  function changePlace(value){
    setPlace(value);
    onFieldChange(value,'place',item);
  }
  function changeInitialstate(value){
    setInitialstate(value);
    onFieldChange(value,'initialstate',item);
  }
  function changeCongelationtype(value){
    setCongelationtype(value);
    onFieldChange(value,'congelationtype',item);
  }

  let isSomething = name || (!!barcode && (barcode+'').length>3);

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents modal-contents--edit">

        <div className="absolute-top-left">
          {isSomething ? <Fab aria-label="Dupliquer" size="small" onClick={(e) => onClickClone(item)} title="Dupliquer"><FileCopyIcon /></Fab> : null}
          {isSomething ? <Fab aria-label="Favori" size="small" onClick={(e) => {onClickFavori(item);setSnackbarFavoriOpened(true)}} title="Favori"><StarIcon /></Fab> : null}

          <Snackbar 
            open={snackbarFavoriOpened} 
            autoHideDuration={3000} 
            onClose={handleCloseSnackbar}
            message={'Favori "'+item.name+'" créé !'} />
        </div>

        <div className="absolute-top-right">
          <Fab aria-label="Fermer" size="small" onClick={closeModal} title="Fermer la vue"><CloseIcon /></Fab>
        </div>

        <div className={'item-details ' + itemAdv.colorFlag} 
          data-creationdate={item.creationdate}
          data-lastupdate={item.lastupdate}
          data-expirationdate={item.expirationdate}
          data-congelationdate={item.congelationdate}
          data-decongelationdate={item.decongelationdate}>

          <div className="modal-form">
            <form className="edition" noValidate autoComplete="off">

              <Grid container>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <TextField label="Code barre" autoFocus={true} size="small" fullWidth inputRef={fieldBarcode}
                        value={barcode} 
                        onChange={(e) => handleChangeBarcode(e.target.value)}/>
                    </Grid>
                    <Grid item xs={2} className="text-right">
                      <Fab aria-label="Scanner le code barre" size="small" onClick={() => setCamera(!camera)} title="Scanner le code barre">{camera ? <StopIcon/> : <CameraAltIcon />}</Fab>
                    </Grid>
                  </Grid>
                  {camera ? (
                  <div className="container">
                    {camera && <Scanner onDetected={onDetected} onReset={() => {}} />}
                  </div>
                  ) : null}
                  {matchingModelsBarcode.length>0 ? (
                  <div className="m-v-05">
                    {matchingModelsBarcode.map( (model,index) => (
                      <Model key={index} 
                        model={model} 
                        onClick={(e) => copyFromModel(model)}
                        theme={'chip'}/>
                    ) )}
                  </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Nom" size="small" fullWidth ref={fieldName}
                    value={name} 
                    onChange={(e) => handleChangeName(e.target.value)}/>

                  {matchingModels.length>0 ? (
                  <div className="m-v-05">
                    {matchingModels.map( (model,index) => (
                      <Model key={index} 
                        model={model} 
                        onClick={(e) => copyFromModel(model)}
                        theme={'chip'}/>
                    ) )}
                  </div>
                  ) : null}
                </Grid>


                { isSomething ? (
                <div>
                  <Grid item xs={12}>

                    <Paper className="p-1 mv-1">
                      <h3>Catégories</h3>
                      <Grid container>

                        <Grid item xs={6} className="pr-1">
                          <FormControl size="small" fullWidth>
                            <InputLabel id="label-type">Type</InputLabel>
                            <Select labelId="label-type" labelWidth={80} name="type" 
                              value={item.type!==undefined ? item.type : ''} 
                              onChange={(e) => onFieldChange(e.target.value,'type',item)}>
                              { S_Items.TYPES.map( (type,index) => (
                                <MenuItem value={type.id} key={index}>
                                  <ListItemIcon className="list-item-icon">
                                    <i className={'icon-'+type.svg}></i> 
                                  </ListItemIcon>
                                  { type.name }
                                </MenuItem>
                              ) ) }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          {item.type!==undefined && subtypes.length>0 ? (
                            <FormControl size="small" fullWidth>
                              <InputLabel id="label-subtype">Sous-type</InputLabel>
                              <Select labelId="label-subtype" labelWidth={80} name="subtype" 
                                value={item.subtype!==undefined ? item.subtype : ''} 
                                onChange={(e) => onFieldChange(e.target.value,'subtype',item)}>
                                { S_App.getByID(S_Items.TYPES,item.type).subtypes.map( (subtype,index) => (
                                  <MenuItem value={subtype.id} key={index}>{ subtype.name }</MenuItem>
                                ) ) }
                              </Select>
                            </FormControl>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Paper>

                  </Grid>
                  

                  <Grid item xs={12}>

                    <Paper className="p-1 mv-1">
                      <h3>Expiration</h3>
                      <Grid container>
                        <Grid item xs={6} className="pr-1">
                          <FormControl component="fieldset" size="small">
                            <RadioGroup name="expirationtype" row aria-label="Type expiration"
                              value={item.expirationtype!==undefined ? item.expirationtype : 'DLC'} 
                              onChange={(e) => onFieldChange(e.target.value,'expirationtype',item)}>
                              { S_Items.EXPIRATIONTYPES.map( (type,index) => (
                                <FormControlLabel key={index} value={type} control={<Radio />} label={type} />
                              ) ) }
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label="Expiration" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
                            value={S_Date.formatDateForMaterial(item.expirationdate)}
                            onChange={(e) => onFieldChange(e.target.value,'expirationdate',item)}/>
                        </Grid>
                      </Grid>
                    </Paper>

                  </Grid>

                  <Grid item xs={12}>

                    <Paper className="p-1 mv-1">
                      <h3>Congélation</h3>
                      <Grid container>
                        <Grid item xs={6} className="pr-1">
                          <TextField label="Congélation"type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
                            value={S_Date.formatDateForMaterial(item.congelationdate)}
                            onChange={(e) => onFieldChange(e.target.value,'congelationdate',item)}/>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField label="Décongélation" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
                            value={S_Date.formatDateForMaterial(item.decongelationdate)}
                            onChange={(e) => onFieldChange(e.target.value,'decongelationdate',item)}/>
                        </Grid>
                      </Grid>
                      <h3>Ouverture</h3>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField label="Ouverture" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
                            value={S_Date.formatDateForMaterial(item.openingdate)}
                            onChange={(e) => onFieldChange(e.target.value,'openingdate',item)}/>
                        </Grid>
                      </Grid>
                    </Paper>

                  </Grid>

                  <Grid item xs={12}>

                    <Paper className="p-1 mv-1">
                      <h3>Stockage</h3>
                      <Grid container>
                        <Grid item xs={6} className="pr-1">
                          <FormControl size="small" fullWidth>
                            <InputLabel id="label-conservationtype">Avant ouverture</InputLabel>
                            <Select labelId="label-conservationtype" labelWidth={80} name="conservationtype" 
                              value={item.conservationtype!==undefined ? item.conservationtype : ''} 
                              onChange={(e) => onFieldChange(e.target.value,'conservationtype',item)}>
                              { S_Items.CONSERVATIONTYPES.map( (type,index) => (
                                <MenuItem value={type} key={index}>{ type }</MenuItem>
                              ) ) }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl size="small" fullWidth>
                            <InputLabel id="label-conservationtype2">Après ouverture</InputLabel>
                            <Select labelId="label-conservationtype2" labelWidth={80} name="conservationtype2" 
                              value={item.conservationtype2!==undefined ? item.conservationtype2 : ''} 
                              onChange={(e) => onFieldChange(e.target.value,'conservationtype2',item)}>
                              { S_Items.CONSERVATIONTYPES.map( (type,index) => (
                                <MenuItem value={type} key={index}>{ type }</MenuItem>
                              ) ) }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>

                  </Grid>

                  <div className="text-left">
                    <div>Etat initial</div>
                    <ToggleButtonGroup value={initialstate} exclusive onChange={(event, newAlignment) => changeInitialstate(newAlignment)} aria-label="Etat initial">
                      <ToggleButton value="Frais" aria-label="Frais">
                        Frais
                      </ToggleButton>
                      <ToggleButton value="Surgelé" aria-label="Surgelé">
                        Surgelé
                      </ToggleButton>
                      <ToggleButton value="Conserve" aria-label="Conserve">
                        Conserve
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  <div className="text-left">
                    <div>Lieu de conservation</div>
                    <div className="overflow-x">
                      <ToggleButtonGroup value={place} exclusive onChange={(event, newAlignment) => changePlace(newAlignment)} aria-label="Lieu de conservation">
                        <ToggleButton value={S_Models.PLACE_CELLIER} aria-label={S_Models.PLACE_CELLIER}>
                          {S_Models.PLACE_CELLIER}
                        </ToggleButton>
                        <ToggleButton value={S_Models.PLACE_PLACARD} aria-label={S_Models.PLACE_PLACARD}>
                          {S_Models.PLACE_PLACARD}
                        </ToggleButton>
                        <ToggleButton value={S_Models.PLACE_COMPTOIR} aria-label={S_Models.PLACE_COMPTOIR}>
                          {S_Models.PLACE_COMPTOIR}
                        </ToggleButton>
                        <ToggleButton value={S_Models.PLACE_FRIGO} aria-label={S_Models.PLACE_FRIGO}>
                          {S_Models.PLACE_FRIGO}
                        </ToggleButton>
                        <ToggleButton value={S_Models.PLACE_FRIZER} aria-label={S_Models.PLACE_FRIZER}>
                          {S_Models.PLACE_FRIZER}
                        </ToggleButton>
                        <ToggleButton value={S_Models.PLACE_CONGEL} aria-label={S_Models.PLACE_CONGEL}>
                          {S_Models.PLACE_CONGEL}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>

                  <div className="text-left mv-1">
                    <FormControl size="small" fullWidth>
                      <InputLabel id="label-congelationtype">Durée de conservation au congélateur</InputLabel>
                      <Select labelId="label-congelationtype" labelWidth={80} name="congelationtype" 
                        value={congelationtype} 
                        onChange={(e) => changeCongelationtype(e.target.value)}>
                        { S_Models.CONGELATION_TYPES.map( (type,index) => (
                          <MenuItem value={type.id} key={index}>{ type.name }</MenuItem>
                        ) ) }
                      </Select>
                    </FormControl>
                  </div>
                  
                  

                  <Grid item xs={12}>
                    <hr/>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField label="Est prévu pour" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
                      value={S_Date.formatDateForMaterial(item.previsionaldate)}
                      onChange={(e) => onFieldChange(e.target.value,'previsionaldate',item)}/>
                  </Grid>
                </div>
                ) : null }

                
              </Grid>
              

            </form>
          </div>
        </div>

        <Grid container spacing={2}>

          <Grid item xs={12} className="text-center">
            <Fab variant="extended" onClick={closeModal} color="primary"><CloseIcon /> OK</Fab>
          </Grid>

          { isSomething && !item.openingdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToOpen(item)}>Ouvrir</Button>
          </Grid>
          ) : null }

          { isSomething && !item.congelationdate && !item.decongelationdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToCongel(item)}>Mettre au congèl</Button>
          </Grid>
          ) : null }

          { isSomething && !!item.congelationdate && !item.decongelationdate ? (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<TrendingFlatIcon />} fullWidth={true} onClick={(e) => onClickToFrigo(item)}>Mettre au frigo (décongélation)</Button>
          </Grid>
          ) : null }

          <Grid item xs={12}>
            <Button variant="outlined" startIcon={<DeleteIcon />} className="wide" onClick={(e) => onClickDelete(item)}>Supprimer</Button>
          </Grid>
        </Grid>

      </div>
    </Modal>
  );
}

export default ModalItemEdition;
